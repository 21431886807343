/**
 * @param {string} str
 * @return {string}
 */
function toAscii(str) {
  str = str + '';
  return str.normalize("NFD").replace(/\p{Diacritic}/gu, "");
}

export {
  toAscii
}
