import * as yup from 'yup';
import { Storage, StorageEvent } from '../rfu/storage';

/**
 * @typedef {import('./sip-client.js').NumID} NumID
 */


const MAX_CALLS = 200;
const CALLS_STORAGE_KEY = 'calls-log';


// Call LOG storage class
// ========================================

/**
 * Call Logging class leveragging IndexedDB storage.
 *
 * @param {Object} options
 * @param {function} options.eventListener
 */
class Log extends Storage {
  constructor(options) {
    const { listener } = {...options};
    const transformer = (calls) => {
      if (!(calls instanceof Array)) {
        calls = [];
      }
      return calls.map(callObj => new Call({...callObj}));
    };

    super({
      name: 'CallLog', storageKey: CALLS_STORAGE_KEY,
      schema: yup.array().of(callAttrSchema),
      listener, transformer,
      defaultContent: [],
    })
  }

  /**
   * @param {Call} call
   */
  async addCall(call) {
    this.content.push(call);
    // this should always remove only one element, thus the cycle should not
    // affect perfomance and is here only as a precaution.
    while (this.content.length > MAX_CALLS) {
      this.content.shift();
    }
    await this.save();
    this.signalEvent(StorageEvent.UPDATED);
  }
}


// Call object
// ========================================

const CallDirection = {
  IN: 'IN',
  OUT: 'OUT',
};
Object.freeze(CallDirection);


/**
 * Call constructor atributes schema.
 */
const callAttrSchema = yup.object().shape({
  src: yup.string().required(),
  srcName: yup.string().required(),
  dst: yup.string().required(),
  dstName: yup.string().required(),
  direction: yup.string().oneOf(
    [CallDirection.IN, CallDirection.OUT]).required(),
  timeInvited: yup.number().required(),
  timeAccepted: yup.number(),
  timeTerminated: yup.number(),
});


/**
 * Call model
 */
class Call {
  constructor(attrs) {
    const {
      src, srcName, dst, dstName, direction,
      timeInvited, timeAccepted, timeTerminated,
    } = callAttrSchema.cast(attrs);
    this.src = src;
    this.srcName = srcName;
    this.dst = dst;
    this.dstName = dstName;
    this.direction = direction;
    this.timeInvited = timeInvited;
    this.timeAccepted = timeAccepted;
    this.timeTerminated = timeTerminated;
  }

  nameNumDirection() {
    const incoming = this.direction === CallDirection.IN;
    const outgoing = this.direction === CallDirection.OUT;
    const num = incoming ? this.src : this.dst;
    const name = incoming ? this.srcName : this.dstName;
    return { name, num, incoming, outgoing };
  }

  toMinSec() {
    const delta = (this.timeAccepted && this.timeTerminated)
          ? this.timeTerminated - this.timeAccepted : 0;
    const totalSeconds = Math.round(delta / 1000);
    const min = Math.floor(totalSeconds / 60);
    const sec = totalSeconds % 60;
    return { min, sec, delta, totalSeconds };
  }

  /**
   * @param {NumID} srcID
   * @param {NumID} dstID
   * @param {str} direction
   * @return {Call}
   */
  static fromIDs(srcID, dstID, direction) {
    const timeInvited = Date.now();
    return new Call({
      src: srcID.num, srcName: srcID.name,
      dst: dstID.num, dstName: dstID.name,
      direction, timeInvited,
    });
  }
}


export {
  Log,
  Call,
  CallDirection,
  CALLS_STORAGE_KEY,
}
