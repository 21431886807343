import React, { useState } from 'react';
import PT from 'prop-types';
import { Alert, Button, Modal } from 'react-bootstrap';
import { FontAwesomeIcon as FAIcon } from '@fortawesome/react-fontawesome';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

import S from './strings';
import I from './icons';
import CSS from './style/rfu.scss';


/**
 * Render an icon from Fontawesome or a src.
 */
function Icon(props) {
  const { src, className: cls, title, ...divProps } = props;
  let classNames = ['icon'];
  if (cls) { classNames.push(cls); }
  let className = classNames.join(' ');

  // referenced file
  if (typeof src === 'string') {
    return (
      <img
        className={className}
        src={src.startsWith('file:/') ? src.substr(6).replace('/','') : src}
        title={title} alt="" {...divProps}
      />
    );
  }

  // is it an fontawesome icon object?
  if (typeof src === 'object' && src.icon && src.prefix && src.iconName) {
    return (
      <FAIcon
        className={className}
        icon={src} title={title}
        {...divProps}
      />
    );
  }

  // otherwise treat it as a component itself
  const SVGIcon = src;
  return (
    <span title={title} className="iconWrapper">
      <SVGIcon className={className} {...divProps} />
    </span>
  );
}
Icon.propTypes = {
  src: PT.oneOfType([PT.string, PT.object, PT.func]).isRequired,
  title: PT.string,
};


/**
 * Render a modal with action handles.
 */
function ActionModal(props) {
  const className = [props.className, 'ActionModal'].filter(x => x).join(' ');
  const [processing, setProcessing] = useState(false);
  const [customShow, setCustomShow] = useState(false);
  const is_processing = processing || props.processing;
  // callback for "operation done" sent to handleClose and handleAction props
  // functions
  const setDone = () => setProcessing(false);

  const handleAction = () => {
    setProcessing(true);
    props.handleAction(setDone);
  };

  const handleClose = () => {
    setProcessing(true);
    props.handleClose(setDone);
  };

  const nullAction = () => {};

  if (!props.show) {
    return '';
  }
  const alignAction = props.alignAction === 'left' ? 'flex-start'
        : (props.alignAction === 'center' ? 'center' : 'flex-end');
  return (
    /* FIXME: animation is disabled, because of temporary issues of
       react-bootstrap with strict mode */
    <Modal
      className={className}
      show={props.show} onHide={handleClose} animation={false}
    >
      <Modal.Header closeButton={!is_processing}>
        <Modal.Title>{props.heading}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {props.children}
      </Modal.Body>
      <Modal.Footer
        className={is_processing ? 'processing' : ''}
        style={{justifyContent: alignAction}}
      >
        { props.customActions ?
          <div className="ActionModal__customActions">
            <Button size="sm" onClick={() => setCustomShow(!customShow)}>
              <Icon src={customShow ? I.collapseLeft : I.ellipsis} />
            </Button>
            { customShow ? props.customActions : ''}
          </div> : ''}

        <div className="ActionModal__actions">
          <Button
            className="ActionModal__close"
            variant={props.closeButtonVariant || 'secondary'}
            disabled={is_processing && !props.processingAllowClose}
            onClick={
              is_processing && !props.processingAllowClose
                ? nullAction : handleClose
            }
          >
            {props.closeName || S.close}
          </Button>

          { props.handleAction ?
            <Button
              className="ActionModal__action"
              variant={props.actionButtonVariant || 'success'}
              disabled={is_processing}
              onClick={is_processing ? nullAction : handleAction}
            >
              {props.actionName || S.save}
            </Button> : '' }
        </div>
      </Modal.Footer>
    </Modal>
  );
}
ActionModal.propTypes = {
  show: PT.bool.isRequired,
  heading: PT.string.isRequired,
  handleClose: PT.func.isRequired,
  className: PT.string,
  processing: PT.bool,
  processingAllowClose: PT.bool,
  actionName: PT.string,
  closeName: PT.string,
  actionButtonVariant: PT.string,
  closeButtonVariant: PT.string,
  handleAction: PT.func,
  alignAction: PT.string,
  customActions: PT.any,
};


/**
 * Component to handle content loading.
 *
 * Displays loading status and content when ready or error when loading fails.
 * ``connStatus`` and ``connError`` can be passed as ``Array`` when processing
 * status and error from multiple sources/requests.
 *
 * When ``status === null`` a loader icon is rendered.
 *
 * When ``status === false`` an error is rendered.
 *
 * When ``status === true`` the content in ``props.children`` is rendered.
 *
 * .. note::
 *
 *    The loader *does not* prevent React from initialising ``props.children``
 *    components (i.e. running their inner code), it only decides whether to
 *    render them into the DOM.  Thus, if some ``props.children`` component
 *    depends on ``status === true`` to render correctly, it has to be dealt
 *    with in the Loader's parent component, like so:
 *
 *    .. code-block:: jsx
 *
 *      <Loader connStatus={status} connError={error}>
 *        { status ? <DoesNotCrashWhenStatusIsNullOrFalse /> : null}
 *      </Loader>
 *
 * Uses :js:func:`reduceStatus` to calculate resulting ``status``.
 *
 * @param {Object} props - component ``props`` object
 * @param {boolean|boolean[]|null} connStatus - response status
 * @param {LoadError|LoadError[]|null} [connError] - response status
 * @param {...*} divProps.* - other component properties, passed to the
 *   wrapper ``div`` element of ``props.children``.
 */
function Loader(props) {
  let {loadStatus, loadError, ...divProps} = props;
  if (!(loadError instanceof Array)) {
    loadError = [loadError];
  }
  if (!(loadStatus instanceof Array)) {
    loadStatus = [loadStatus];
  }
  const status = reduceLoadStatus(...loadStatus);

  let content;
  if (!status) {
    // proces errors
    if (status === false) {
      content = loadError.map((cErr, idx) => {
        let errMsg;
        let errType;
        if (typeof cErr === 'string') {
          errMsg = cErr;
          errType = S.loadingDataFailed;
        } else {
          errMsg = (cErr && cErr.detail) || S.loadingDataFailedError;
          errType = cErr && cErr.type && cErr.code
            ? (`${cErr.code}: ${cErr.type}`)
            : (S.loadingDataFailed);
        }
        return (
          <div key={idx} className="alert">
            <Alert variant="danger">
              <Alert.Heading>{errType}</Alert.Heading>
              <pre>{errMsg}</pre>
            </Alert>
          </div>
        );
      });
    }

    // process loading
    else {
      content = (
        <div className="alert loading">
          <Icon className={CSS.spinner + ' spinner'} src={I.spinner} />
        </div>
      );
    }
  } else {
    content = props.children;
  }
  return (
    <div {...divProps}>
      {content}
    </div>
  );
}
Loader.propTypes = {
  connStatus: PT.oneOfType([
    PT.bool, PT.arrayOf(PT.bool)
  ]),
  connError: PT.oneOfType([
    PT.string, PT.object, PT.arrayOf(PT.string), PT.arrayOf(PT.object)
  ]),
};


/**
 * Combine multiple request states into one.
 *
 * Any false leads results into ``false``, ``null`` and ``true`` resolve to
 * ``null``.
 *
 * @param {...boolean|null} status - connection status results
 * @return {boolean|null} resulting status
 */
function reduceLoadStatus(...status) {
  return status.reduce((a, b) =>
    (a === false || b === false) ? false : a && b
  );
}


/**
 * Display a full-width loading "stripe" at the top of the parent component.
 */
function LoadingProgress(props) {
  const progress = Math.min(Math.max(props.progress || 0), 100);
  const placement = props.placement || 'top';
  const isDone = progress === 100;

  // do not render when fully loaded
  if (isDone && !props.displayDone) {
    return '';
  }

  // render loading progress
  const style = { position: 'absolute', width: '100%', left: '0'};
  style[placement] = 0;
  const styleStripe = { position: 'relative', width: `${progress}%` };
  return (
    <div className={`LoadingProgress ${isDone ? 'done' : ''}`} style={style}>
      <div className="LoadingProgress__stripe" style={styleStripe}></div>
    </div>
  );
}
LoadingProgress.propTypes = {
  progress: PT.number.isRequired,
  placement: PT.oneOf(['top', 'bottom']),
  displayDone: PT.bool,
};


/**
 * :js:func:`useFetch` response object.
 *
 * @class
 * @constructor
 */
class LoadError {
  constructor({code, type, detail}) {
    /**
     * Error code
     * @type {number}
     */
    this.code = code;
    /**
     * Error type
     * @type {string}
     */
    this.type = type;
    /**
     * Error detail
     * @type {string}
     */
    this.detail = detail;
  }
}


/**
 * Display a confirmation dialogue.
 *
 * @param {object} options - options.
 * @param {string} options.title - dialogue title
 * @param {string} options.message - message
 * @param {function} options.onAccept - accept action
 * @param {function} options.onCancel - cancel action
 */
function confirmAction(options) {
  options = options || {};
  options.title = options.title || S.confirmDefaultTitle;
  options.message = options.message || S.confirmDefaultMessage;
  options.onAccept = options.onAccept || (() => null);
  options.onCancel = options.onCancel || (() => null);
  confirmAlert({
    title: options.title,
    message: options.message,
    buttons: [
      { label: S.ok, onClick: options.onAccept },
      { label: S.cancel, onClick: options.onCancel },
    ],
  });
}


export {
  ActionModal,
  Icon,
  Loader,
  LoadingProgress,
  LoadError,

  confirmAction,
  reduceLoadStatus,
}
