import LocalizedString from 'react-localization';


const languages = {
  cs: {title: 'Česky', icon: null},
  en: {title: 'English', icon: null},
};


const strings = new LocalizedString({
  cs: {
    cancel: 'Zrušit',
    close: 'Zavřít',
    ok: 'OK',
    save: 'Uložit',

    confirmDefaultTitle: 'Nutno potvrdit',
    confirmDefaultMessage: 'Opravdu to chcete udělat?',
  },

  en: {
    cancel: 'Cancel',
    close: 'Close',
    ok: 'OK',
    save: 'Save',

    confirmDefaultTitle: 'Confirmation required',
    confirmDefaultMessage: 'Do you really want to do this?',
  },
});


export {
  languages,
  strings,
}
export default strings
