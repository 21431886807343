/***
 * General storage class.
 */

import * as yup from 'yup';

const yupFunc = (attrName) => yup.mixed().default((_ => null)).test(
  attrName, ({path}) => `${path} is not a function`,
  async value => typeof value === 'function'
)

const storageOptions = yup.object().shape({
  name: yup.string().required(),
  storageKey: yup.string().required(),
  schema: yup.object().required(),
  transformer: yupFunc('data transformer'),
  listener: yupFunc('event listener') ,
});


/**
 * Storage event types.
 */
const StorageEvent = {
  LOADED: 'LOADED',
  SAVED: 'SAVED',
  UPDATED: 'UPDATED',
  ERROR: 'ERROR',
  WARNING: 'WARNING',
};
Object.freeze(StorageEvent);


/**
 * Call Logging class leveragging IndexedDB storage.
 *
 * @param {Object} options
 * @param {function} options.eventListener
 */
class Storage {
  constructor(options) {
    const {
      name, storageKey, schema, transformer, listener, defaultContent,
    } = storageOptions.cast(options);
    /** @type {string} */
    this.name = name;
    /** @type {string} */
    this.storageKey = storageKey;
    /** @type {string} */
    this.schema = schema;
    /** @type {string} */
    this.transformer = transformer;
    /** @type {function} */
    this.listener = listener || (_ => null);
    /** @type {} */
    this.content = defaultContent;

    /** @type {boolean} */
    this.isLoaded = false;
    this.load();
  }

  /**
   * Get current storage content.
   */
  get() {
    return this.content;
  }

  /**
   * Get current storage content.
   */
  set(content) {
    this.content = content;
  }

  /**
   * Load content from localStorage.
   */
  async load() {
    try {
      let stored = JSON.parse(localStorage.getItem(this.storageKey));
      await this.schema.validate(stored);
      // populate the call with instances of Call
      stored = this.schema.cast(stored);
      if (this.transformer) {
        stored = this.transformer(stored);
      }
      this.content = stored;
    } catch (error) {
      console.debug(`Error loading storage "${this.name}": ${error}`);
    }
    this.isLoaded = true;
    this.signalEvent(StorageEvent.LOADED);
    return this.content;
  }

  /**
   * Save content to localStorage.
   */
  async save() {
    localStorage.setItem(this.storageKey, JSON.stringify(this.content));
    this.signalEvent(StorageEvent.SAVED);
  }

  async clear() {
    this.content = [];
    await this.save();
    this.signalEvent(StorageEvent.UPDATED);
  }

  signalEvent(event, value) {
    console.debug(`Storage "${this.name}": ${event}`);
    this.listener(event, value);
  }
}


export {
  Storage,
  StorageEvent,
}
