import { envVar } from '../rfu/env';

const API_HOST = envVar('REACT_APP_TIS_HOST')
  || `${window.location.protocol}//${window.location.host}`;

const URLS = {

  // AUTH
  // ========================================
  user_profile: 'auth/profile',

  // VOIP
  // ========================================
  voip_sip_users: 'voip/sip-accounts',
  voip_phone_contacts: 'voip/phone-contacts',

};

// transform to full URL with scheme, host and prefix
for (let key in URLS) {
  let url = URLS[key];
  if (url[0] === '!') {
    URLS[key] = `${API_HOST}/${url.substr(1)}`;
  } else {
    URLS[key] = `${API_HOST}/api/${url}`;
  }
}

export default URLS;
