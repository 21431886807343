import React, { useContext, useState } from 'react';
import { useFormik } from 'formik';
import { Alert, Button } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import { useFetch } from '../rfu/requests';
import { AuthContext } from '../core/context';
import {
  User, authSchema, LoginType, SipUser, sipAccountsSchema
} from '../core/auth';
import S from '../core/strings';
import UA from '../core/urls_api';
import { postMsg } from '../core/notifications';


/**
 * Main SIP login component.
 */
function Login() {
  const auth = useContext(AuthContext);
  const [tisLogin, setTisLogin] = useState(true);
  const [errorMsg, setErrorMsg]  = useState('');
  const [errorDetail, setErrorDetail]  = useState('');

  // get user token and profile
  const { doFetch } = useFetch(
    UA.voip_sip_users, null,
    {execute: false, noAuth: true}
  );

  /**
   * @param {Array} accounts
   */
  const sortedSipAccounts = (accounts) => {
    const sa = accounts.map(
      a => new SipUser(a.name, a.password, Boolean(a['default'])))
    return sa.sort(SipUser.sort);
  };

  const doTisLogin = (username, password) => {
    setErrorMsg('');
    setErrorDetail('');
    doFetch({
      headers: {Authorization: `Basic ${btoa(username + ":" + password)}`},
    },{
      onResponse: async ({status, error, response}) => {
        postMsg('debug', 'after login, 1st');
	if (status) {
          try {
            await sipAccountsSchema.validate(response);
            const accts = sipAccountsSchema.cast(response);
            auth.setUser(new User({
              username, password, tisLogin,
              sipAccounts: sortedSipAccounts(accts.accounts),
            }));
            postMsg('login', { username, tisLogin });
          } catch (err) {
            setErrorMsg(S.sipNoAccounts);
            setErrorDetail(err);
          }
        } else if (error) {
          setErrorMsg(error.detail);
        }
      }
    });
  };

  const onSubmit = (credentials) => {
    const { username, password } = credentials;
    if (!tisLogin) {
      credentials.sipAccounts = [new SipUser(username, password)];
      auth.setUser(new User(credentials));
    } else {
      doTisLogin(username, password);
    }
  };

  return (
    <div className="Login">
      <div>
        <div className="Login__title">
          {tisLogin ? S.sipTisLogin : S.sipLogin}
        </div>
        { errorMsg ?
          <Alert variant="danger">
            <p>{errorMsg}</p>
            { errorDetail ? <p><small>{String(errorDetail)}</small></p> : null }
          </Alert> : null
        }
        <LoginForm
          onSubmit={onSubmit} tisLogin={tisLogin} setTisLogin={setTisLogin}
        />
      </div>
    </div>
  );
}


/**
 * SIP login form.
 */
function LoginForm(props) {
  const { onSubmit, tisLogin, setTisLogin } = props;
  const type = tisLogin ? LoginType.TIS : LoginType.SIP;
  const formik = useFormik({
    initialValues: {
      username: '',
      password: '',
    },
    onSubmit: (values, { setSubmitting }) => {
      onSubmit(values);
      setSubmitting(false);
    },
    validationSchema: authSchema,
  });
  return (
    <div id="SipLoginForm">
      <Form onSubmit={formik.handleSubmit}>
        <Form.Group>
          <Form.Label htmlFor="username">{S.sipUsername(type)}</Form.Label>
          <Form.Control
            id="username"
            placeholder={S.sipUsernamePlaceholder(type)}
            aria-label={S.sipUsername(type)}
            aria-describedby="username"
            {...formik.getFieldProps('username')}
          />
        </Form.Group>
        <Form.Group>
          <Form.Label htmlFor="password">{S.sipPassword(type)}</Form.Label>
          <Form.Control
            type="password"
            id="password"
            placeholder={S.sipPasswordPlaceholder(type)}
            aria-label={S.sipPassword(type)}
            aria-describedby="password"
            {...formik.getFieldProps('password')}
          />
        </Form.Group>
        <Form.Group>
           <Form.Check
             type="checkbox" label={S.sipTisLoginCheck}
             checked={tisLogin} onChange={(e) => setTisLogin(e.target.checked)}
           />
        </Form.Group>
        <Form.Group>
          <Button type="submit" variant="primary" className="btn-submit">
            {S.signIn}
          </Button>
        </Form.Group>
      </Form>
    </div>
  );
}

export default Login;
