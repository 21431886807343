import React, { useState } from 'react';
import { User } from './auth';


/**
 * Main context provider.
 *
 * This component wraps all general context providers used by the App:
 */
function AppContextProvider(props) {
  return (
    <AuthContextProvider>
      {props.children}
    </AuthContextProvider>
  );
}


/**
 * Auth context provider
 *
 * Context provider for :js:attr:`AuthContext`.
 */
function AuthContextProvider(props) {
  const setUser = user => {
    if (user instanceof User && (user.sipAccounts || []).length) {
      localStorage.setItem('user', JSON.stringify(user));
    } else {
      user = null;
      localStorage.removeItem('user');
    }
    setState(new AuthContextInstance(user, setUser));
  };
  const [state, setState] = useState(new AuthContextInstance(null, setUser));
  return (
    <AuthContext.Provider value={state}>
      {props.children}
    </AuthContext.Provider>
  );
}


/**
 * Instance of :js:attr:`AuthContext`.
 * @class
 * @constructor
 */
class AuthContextInstance {
  constructor(user, setUser) {
    /**
     * User object instance.
     * @type {User}
     */
    this.user = user || null;
    /**
     * Context setter.
     * @type {function}
     * @param {User} user
     */
    this.setUser = setUser || (user => {});
  }
}


/**
 * Authentication context object.
 *
 * When accessed through ``useContext`` the returned object has a structure of
 * :js:class:`AuthContextInstance`.
 */
const AuthContext = React.createContext({
  user: null,
  setUser: user => {},
});


export {
  AppContextProvider,
  AuthContext,
  AuthContextInstance,
};
