import * as yup from 'yup';
import { Storage } from '../rfu/storage';

const CONFIG_STORAGE_KEY = 'config';


// Config storage class
// ========================================

/**
 * Call Logging class leveragging IndexedDB storage.
 *
 * @param {Object} options
 * @param {function} options.eventListener
 */
class Config extends Storage {
  constructor(options) {
    const { listener } = {...options};
    const transformer = obj => new Conf({...obj});
    super({
      name: 'Config', storageKey: CONFIG_STORAGE_KEY,
      schema: configAttrSchema,
      defaultContent: new Conf(),
      listener, transformer,
    });
    // enable typing hints
    /** @type {Conf} */
    this.content = this.content || new Conf();
  }
}


/**
 * Config constructor atributes schema.
 */
const configAttrSchema = yup.object().shape({
  pushNotifications: yup.boolean().default(false),
});


/**
 * Config object model.
 *
 * @param {Object} settings
 * @param {boolean} settings.pushNotifications
 */
class Conf {
  constructor(settings) {
    const { pushNotifications } = {...settings}
    /** @type {boolean} */
    this.pushNotifications = pushNotifications || false;
  }
}


export default Config;
export {
  Config,
  CONFIG_STORAGE_KEY,
}
