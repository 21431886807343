import { React, useEffect, useRef, useState } from 'react';
import PT from 'prop-types';
import { Form } from 'react-bootstrap';

import S from '../core/strings';
import { SipClient, NumID } from '../voip/sip-client';
import { isValidNum, isValidNumPart } from '../voip/validators';
import * as CI from './CallIcons';


/**
 * Dialer.
 *
 * @param {object} props;
 * @param {SipClient} props.sipClient;
 * @param {boolean} props.focusDial;
 * @param {function} props.setFocusDial;
 */
function Dialer(props) {
  const { sipClient } = props;
  const dialRef = useRef();

  const [dial, setDial] = useState('');
  const handleDial = e => {
    const val = e.target.value;
    if (isValidNumPart(val)) {
      setDial(val);
    }
  };

  const call = () => sipClient.call(new NumID(dial));

  useEffect(() => {if (props.focusDial) {
    props.setFocusDial(false);
    dialRef.current && dialRef.current.focus();
  }});

  return (
    <div className="Dialer">
      <Form.Group className="form-group">
        <Form.Label htmlFor="number-dial">{S.sipDialNumber}:</Form.Label>
        <Form.Control
          ref={dialRef} id="number-dial"
          value={dial} onChange={handleDial}
        />
      </Form.Group>
      <div className="actionIcons">
        <CI.Dial disabled={!isValidNum(dial)} onClick={call} size={150} />
      </div>
    </div>
  );
}
Dialer.propTypes = {
  sipClient: PT.instanceOf(SipClient).isRequired,
  focusDial: PT.bool,
  setFocusDial: PT.func,
};


export default Dialer;
