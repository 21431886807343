/***
 * Concurrency related functions and classes.
 */

const MAX_FIFO_POOL_SIZE = 1000;

/**
 * Concurrent process manager.
 */
class FIFOPool {
  /**
   * Init FIFOPool
   *
   * @param {number} size - number of allowed concurrent tasks
   * @param {object} params - params
   * @param {number} params.delay - delay before launching next task (in ms)
   */
  constructor(size=1, params) {
    params = params || {};
    this.delay = params.delay || 0;
    this.size = Math.min(Math.max(parseInt(size) || 1, 1), MAX_FIFO_POOL_SIZE);
    this.processes = [...Array(size)];
    this.queue = [];
    this.nextTicket = 0;
  }

  /**
   * Register new queue item.
   *
   * @param {function} nextPlease - callback when the item is due
   * @return {number} - ticket id
   */
  register(nextPlease) {
    const ticket = this.nextTicket;
    this.queue.push({ ticket, nextPlease });
    this.nextTicket++;
    this.callNext();
    return ticket;
  }

  /**
   * Shift the queue when the item is finished.
   *
   * @param {number} ticket - ticketID of the finished process.
   */
  finished(ticket) {
    const slot = this.processes.indexOf(ticket);
    if (~slot) {
      this.processes[slot] = undefined;
    }
    this.queue = this.queue.filter(x => x.ticket !== ticket);
    setTimeout(() => this.callNext(), this.delay);
  }

  /**
   * Shift the queue if an empty slot is found.
   *
   * Execute nextPlease with callback to run when process completed on
   * "client" side.
   */
  callNext() {
    const emptySlot = this.processes.indexOf(undefined);
    if (~emptySlot && this.queue.length) {
      const ticket = this.queue[0].ticket
      this.processes[emptySlot] = ticket;
      this.queue[0].nextPlease(() => this.finished(ticket));
    }
  }

  /**
   * Quit waiting.
   *
   * Currently just an alias for this.finished.
   * Semantically clean "cleanup" method.
   *
   * @param {number} ticket - ticketID of the finished process.
   */
  quit(ticket) {
    this.finished(ticket);
  }
}


export { FIFOPool };
