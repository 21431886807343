import LocalizedString from 'react-localization';

const DEFAULT_LANG = 'cs';


const languages = {
  cs: {title: 'Česky', icon: null},
  en: {title: 'English', icon: null},
};


const strings = new LocalizedString({
  cs: {
    user: 'uživatel',
    password: 'heslo',

    signIn: 'Přihlásit',
    signOut: 'Odhlásit',

    sipLogin: 'Přihlášení k SIP účtu',
    sipTisLogin: 'Přihlášení k účtu IS TIS',
    sipTisLoginCheck: 'Přihlásit jako uživatel TIS',

    sipNoAccounts: 'Uživatel nemá žádné SIP účty s podporou WebSocket.',

    sipUsername: type => `${type} uživatel`,
    sipUsernamePlaceholder: type => `Uživatel ${type} účtu`,
    sipPassword: type => `${type} heslo`,
    sipPasswordPlaceholder: type => `Heslo k ${type} účtu`,

    sipTabDial: 'Volat',
    sipTabContacts: 'Kontakty',
    sipTabCallLog: 'Historie',

    sipDial: 'Volat',
    sipDialNumber: 'Volat číslo',
    sipDialing: 'Volám',
    sipIncoming: 'Příchozí hovor',
    sipHangup: 'Zavěsit',
    sipAccept: 'Přijmout',
    sipReject: 'Odmítnout',
    sipCall: 'Hovor',
    sipNumbersOnHold: 'Čekající čísla',

    contactSearch: 'Hledat kontakt',
    myPhone: 'Můj telefon',
    noCallsHistory: 'Historie hovorů je prázdná.',

    settings: 'Nastavení',
    settingsNotifications: 'Oznámení',
    settingsPushNotifToggle: 'Povolit push notifikace',
    settingsNotAvailable: 'Nastavení není dostupné.',
    settingsForwarding: 'Přesměrování',
  },

  en: {
    signIn: 'Sign in',
    signOut: 'Sign out',

    sipLogin: 'Sip account login',
    sipTisLogin: 'TIS account login',
    sipTisLoginCheck: 'Log in as TIS user',

    sipNoAccounts: 'The user has no WebSocket enabled SIP accounts',

    sipUsername: type => `${type} user`,
    sipUsernamePlaceholder: type => `Your ${type} username`,
    sipPassword: type => `${type} password`,
    sipPasswordPlaceholder: type => `Your ${type} password`,

    sipTabDial: 'Dial',
    sipTabContacts: 'Contacts',
    sipTabCallLog: 'History',

    sipDial: 'Dial',
    sipDialNumber: 'Dial a number',
    sipDialing: 'Dialing',
    sipIncoming: 'Incomming call',
    sipHangup: 'Hang up',
    sipAccept: 'Accept',
    sipReject: 'Reject',
    sipCall: 'Call',
    sipNumbersOnHold: 'Numbers on hold',

    contactSearch: 'Search for a contact',
    myPhone: 'My Phone',
    noCallsHistory: 'Call log is empty.',

    settings: 'Settings',
    settingsNotifications: 'Notifications',
    settingsPushNotifToggle: 'Enable push notifications',
    settingsNotAvailable: 'Setting not available.',
    settingsForwarding: 'Forwarding',
  },
});


export {
  languages,
  strings,
  DEFAULT_LANG,
}
export default strings
