import React, { useContext, useEffect, useRef, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { Loader } from '../rfu/ui';
import RS from '../rfu/strings';
import {
  pushCapabilityCheck, pushStop, pushToggle
} from '../core/notifications';
import S from '../core/strings';
import { AuthContext } from '../core/context';
import { fetchAuthHeaders } from '../core/auth';

const VAPID_APP_KEY = process.env.REACT_APP_VAPID_KEY;

/**
 * @typedef {import('../core/config.js').default} Config
 * @typedef {import('../core/auth.js').User} User
 */

/**
 * @param {Object} props
 * @param {function} props.closeHook
 * @param {Config} props.config
 * @param {boolean} props.loaded
 */
function Settings(props) {
  const { config, loaded, closeHook } = {...props};
  return (
    <Loader loadStatus={loaded} className="Settings body">
      <div className="Settings__header">
        <div className="Settings__header__title"><h1>{S.settings}</h1></div>
        <div className="Settings__header__action">
          <Button onClick={closeHook}>{RS.ok}</Button>
        </div>
      </div>
      <SettingsBody config={config} />
    </Loader>
  );
}


/**
 * @param {Object} props
 * @param {Config} props.config
 */
function SettingsBody(props) {
  const { config } = {...props};
  /** @type {User} */
  const user = useContext(AuthContext).user;

  // notifications
  const [pushAvailable, setPushAvailable] = useState(null);
  const [pushActive, setPushActive] = useState(
    config.content.pushNotifications);

  return (
    <div className="SettingsBody">
      <SetNotifications {...{
        config, user,
        pushAvailable, setPushAvailable,
        pushActive, setPushActive,
      }} />
      {/* <SetForwarding {...{config, user}} /> */}
    </div>
  );
}


/**
 * @param {Object} props
 * @param {Config} props.config
 * @param {User} props.user
 * @param {boolean} props.pushAvailable
 * @param {function} props.setPushActive
 * @param {boolean} props.pushActive
 * @param {function} props.setPushActive
 */
function SetNotifications(props) {
  const {
    config, user,
    pushAvailable, setPushAvailable,
    pushActive, setPushActive,
  } = {...props}
  const isTisLogin = user.tisLogin;
  const mountRef = useRef();

  const pushToggleActive = async () => await togglePush(
    config, user, pushActive, setPushActive, setPushAvailable
  );

  useEffect(() => { if (!mountRef.current) {
    mountRef.current = true;

    const checkPush = async () => {
      if (!isTisLogin || process.env.NODE_ENV !== 'production') {
        setPushAvailable(false);
      } else {
        try {
          await pushCapabilityCheck(VAPID_APP_KEY);
          setPushAvailable(true);
        } catch {
          setPushAvailable(false);
        }
      }
    };
    // check if push is available
    checkPush();
  }});

  return (
    <>
      <h2>{S.settingsNotifications}</h2>
      <Form.Group>
        <Form.Check
          type="checkbox" label={S.settingsPushNotifToggle}
          disabled={!pushAvailable} onChange={pushToggleActive}
          checked={pushActive}
        />
        { pushAvailable !== true ? (
          <div className="addendum">{S.settingsNotAvailable}</div>
        ) : null }
      </Form.Group>
    </>
  );
}


// /**
//  * @param {Object} props
//  * @param {Config} props.config
//  * @param {User} props.user
//  */
// function SetForwarding(props) {
//   return (
//     <>
//       <h2>{S.settingsForwarding}</h2>
//     </>
//   );
// }


// Utility functions
// ========================================

/**
 * @param {Config} config
 * @param {User} user
 * @param {bool} pushActive
 * @param {function} setPushActive
 * @param {function} setPushAvailable
 */
async function togglePush(
  config, user,
  pushActive=false,
  setPushActive=(()=>null),
  setPushAvailable=(()=>null),
) {
  const basicAuth = fetchAuthHeaders(user).Authorization;
  let active = pushActive;
  try {
    active = await pushToggle(VAPID_APP_KEY, {basicAuth});
  } catch (e) {
    active = false;
    setPushAvailable(false);
  }
  setPushActive(active);
  config.content.pushNotifications = active;
  await config.save();
};


/**
 * @param {User} user
 */
async function clearSettings(user) {
  if (user.tisLogin) {
    const basicAuth = fetchAuthHeaders(user).Authorization;
    await pushStop(VAPID_APP_KEY, { basicAuth });
  }
}


export default Settings;
export {
  Settings,
  clearSettings,
  togglePush,
};
