import { React, useContext } from 'react';
import { Container, Form, Nav, Navbar, NavDropdown } from 'react-bootstrap';
import { AuthContext } from '../core/context';
import { Icon } from '../rfu/ui';
import I from '../core/icons';
import S from '../core/strings';

import { clearSettings } from './Settings';
import { CallState } from '../voip/sip-client';
import { UIState } from './Phone';
import { CONFIG_STORAGE_KEY } from '../core/config';
import { CONTACTS_STORAGE_KEY } from '../voip/contacts';
import { CALLS_STORAGE_KEY } from '../voip/call-log';


function NavBar(props) {
  const { disabled, sipUser, setSipUser, sipState, setUIState } = props;

  const auth = useContext(AuthContext);
  const sipAccounts = auth.user.sipAccounts;
  const signOut = async () => {
    // clear settings
    await clearSettings(auth.user);
    // clear local storage items
    for (const key of [
      CONTACTS_STORAGE_KEY,     // contacts
      CALLS_STORAGE_KEY,        // call log
      CONFIG_STORAGE_KEY        // config
    ]) {
      localStorage.removeItem(key);
    }
    // clear the user
    auth.setUser(null);
  }
  const goHome = () => setUIState(UIState.DEFAULT);
  const openSettins = () => setUIState(UIState.SETTINGS);

  const connState = (
    ~[CallState.OFF, CallState.CONNECTING].indexOf(sipState)
      ? 'offline' : 'online');

  const sipAccountSelect = (
    <Form.Select
      className={`AccountSelect ${connState}`}
      onChange={e => setSipUser(e.target.value)} value={sipUser}
      disabled={disabled}
    >
      { sipAccounts.map((sa, idx) =>
        <option key={idx} value={sa.user}>{sa.user}</option>
      )}
    </Form.Select>
  );

  return (
    <Navbar className="NavBar" bg="light">
      <Container>
        <Navbar.Brand onClick={goHome}>tnPhone</Navbar.Brand>
        <Nav>
          <Nav.Item>
            {sipAccountSelect}
          </Nav.Item>
          <Nav.Item>
            <Nav.Link onClick={() => window.location.reload()}>
              <Icon src={I.reload} />
            </Nav.Link>
          </Nav.Item>
          <NavDropdown
            title={<Icon src={I.menu} title={S.signOut} />}
            align="end" disabled={disabled}
          >
            <NavDropdown.Item as="button" onClick={openSettins}>
              {S.settings}
            </NavDropdown.Item>
            <NavDropdown.Divider />
            <NavDropdown.Item as="button" onClick={signOut}>
              {S.signOut}
            </NavDropdown.Item>
          </NavDropdown>
        </Nav>
      </Container>
    </Navbar>
  )
}


export default NavBar;
