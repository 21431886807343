import React, {
  useCallback, useContext, useEffect, useRef, useState,
} from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import { StorageEvent } from '../rfu/storage';
import { AuthContext  } from '../core/context';
import S from '../core/strings';
import { envVar } from '../core/env';
import { SipClient, CallState, NumID } from '../voip/sip-client';
import { Log } from '../voip/call-log';
import Config from '../core/config';

import NavBar from './NavBar';
import Dialer from './Dialer';
import Contacts from './Contacts';
import CallLog from './CallLog';
import CallControls from './CallControls';
import {Settings, togglePush} from './Settings';
import { postMsg } from '../core/notifications';

/**
 * @typedef {import('../voip/sip-client.js').MessageType} MessageType
 */


const SYNC_TICK_MINUTES = 60;


function Phone() {
  const mountRef = useRef(false);
  const auth = useContext(AuthContext);
  const sipAccounts = auth.user.sipAccounts;

  const serverUrl = envVar('REACT_APP_TIS_SIP_SERVER_URL');
  const serverName = envVar('REACT_APP_TIS_SIP_SERVER_NAME');
  const streamLocal = useRef();
  const streamRemote = useRef();

  const sipClient = useRef();
  const callLog = useRef();
  const config = useRef();

  const [configIsLoaded, setConfigIsLoaded] = useState(null); // false is error
  const [callLogTStamp, setCallLogTStamp] = useState(null);
  const [contactsTStamp, setContactsTStamp] = useState(null);
  const syncTickRef = useRef(null);

  // states
  // ========================================
  const [tab, setTab] = useState(ControlTabs.DIAL);
  const [sipUser, setSipUser] = useState(sipAccounts[0].user);
  const [state, setState] = useState(CallState.OFF)
  const [numID, setNumID] = useState();
  const [numsOnHold, setNumsOnHold] = useState([]);
  // eslint-disable-next-line
  const [msg, setMsg] = useState();
  // eslint-disable-next-line
  const [msgType, setMsgType] = useState();
  // ui state
  const [uiState, setUIState] = useState(UIState.DEFAULT);
  const [focusDial, setFocusDial] = useState(true);

  // methods
  // ========================================
  /**
   * @param {string} user
   */
  const getSipUserPassword = useCallback((user) => {
    for (const sa of sipAccounts) {
      if (sa.user === user) {
        return sa.password;
      }
    }
    return null;
  }, [sipAccounts]);

  /**
   * @param {NumID} nID
   */
  const call = (nID) => {
    sipClient.current.call(nID);
  };

  // SIP events
  // ========================================
  const onState = (userName, st) => {
    console.debug('STATE', userName,  st);
    setState(st);
  }

  const onConnecting = (userName) => {
    console.debug('CONNECTING', userName);
  };

  const onIdle = (userName) => {
    console.debug('IDLE', userName);
  };

  const onOutgoing = (userName, num) => {
    console.debug('OUTGOING', userName, num);
    setUIState(UIState.CALL);
    setNumID(num);
  };

  const onIncoming = (userName, num) => {
    console.debug('INCOMING', userName, num);
    setUIState(UIState.CALL);
    setNumID(num);
  };

  const onNumsOnHold = (userName, nums) => {
    console.debug('ON HOLD', userName, nums);
    setNumsOnHold(nums);
  };

  const onCall = (userName, num) => {
    console.debug('CALL', userName, num);
  };

  const onHangup = (userName) => {
    console.debug('HANGUP', userName);
  };

  const onDisconnect = (userName) => {
    console.debug('DISCONNECT', userName);
  };

  const onMessage = (userName, type, message) => {
    console.debug('MESSAGE', userName, type, message);
    setMsg(message);
    setMsgType(type);
  };

  const eventListeners = {
    onState, onNumsOnHold, onMessage,
    onConnecting, onIdle, onOutgoing, onIncoming, onCall,
    onHangup, onDisconnect,
  };

  // Config events
  const configEvent = (event) => {
    console.debug('CONFIG EVENT', event);
    if (event === StorageEvent.LOADED) {
      /** @type {Config} */
      const sc = config.current;
      setConfigIsLoaded(true);
      // initialize PUSH if loaded for first time
      if (sc.isLoadedDefault) {
        togglePush(sc, auth.user);
      }
    }
  };

  // Log events
  // ========================================
  const callLogEvent = (event) => {
    console.debug('LOG EVENT', event);
    setCallLogTStamp(Date.now());
  };

  // periodic sync
  // ========================================
  const tick = () => {
    const now = Date.now();
    setCallLogTStamp(now);
    setContactsTStamp(now);
  };

  // effects
  // ========================================

  // initiate on mount
  useEffect(() => { if (!mountRef.current) {
    mountRef.current = true;
    sipClient.current = new SipClient({
      serverUrl, serverName, eventListeners,
      login: {username: sipUser, password: getSipUserPassword(sipUser)},
      streamLocal: streamLocal.current,
      streamRemote: streamRemote.current,
    });
    callLog.current = new Log({ listener: callLogEvent });
    config.current = new Config({ listener: configEvent });
  }});

  useEffect(() => () =>
    sipClient.current && sipClient.current.unregister(), []);

  useEffect(() => {
    tick();
    syncTickRef.current = setInterval(tick, SYNC_TICK_MINUTES * 60 * 1000);
    return () => clearInterval(syncTickRef.current);
  }, [])

  // account switch:
  useEffect(() => {
    const sc = sipClient.current
    if (sc && sc.login.username !== sipUser) {
      sc.switchAccount(sipUser, getSipUserPassword(sipUser));
    }
  }, [sipUser, getSipUserPassword]);

  useEffect(() => { postMsg('sipUser', { sipUser }); }, [sipUser]);

  useEffect(() => {
    if (tab === ControlTabs.DIAL) setFocusDial(true);
  }, [tab]);

  useEffect(() => {
    if (uiState === UIState.DEFAULT && tab === ControlTabs.DIAL) {
      setFocusDial(true);
    }
  }, [uiState, tab]);
  // render
  // ========================================

  return (
    <div className={`SipClient ${uiState}`}>
      <NavBar {...{
        setUIState, sipUser, setSipUser, sipState: state,
        disabled: uiState === UIState.CALL,
      }} />
      <div className="SipClient__media" style={{display: 'none'}}>
        <video ref={streamRemote}></video>
        <video ref={streamLocal} muted={true}></video>
      </div>

      { sipClient.current ? (
        <Tabs activeKey={tab} onSelect={key => setTab(key)} >
          <Tab
            eventKey={ControlTabs.DIAL} title={S.sipTabDial}
          >
            <Dialer
              sipClient={sipClient.current}
              {...{focusDial, setFocusDial}}
            />
          </Tab>
          <Tab eventKey={ControlTabs.CONTACTS} title={S.sipTabContacts}>
            <Contacts call={call} contactsTStamp={contactsTStamp} />
          </Tab>
          <Tab eventKey={ControlTabs.CALL_LOG} title={S.sipTabCallLog}>
            <CallLog
              callLog={callLog.current} call={call}
              callLogTStamp={callLogTStamp}
            />
          </Tab>
        </Tabs>
      ) : null }

      { uiState === UIState.CALL ? (
        <CallControls
          client={sipClient.current} state={state}
          baseID={new NumID(sipUser, S.myPhone)}
          numID={numID} numsOnHold={numsOnHold}
          callLog={callLog.current}
          closeHook={() => setUIState(UIState.DEFAULT)}
        />
      ) : null }

      { uiState === UIState.SETTINGS ? (
        <Settings
          config={config.current} loaded={configIsLoaded}
          closeHook={() => setUIState(UIState.DEFAULT)}
        />
      ) : null }

    </div>
  );
}


// ENUMs
// ========================================
const ControlTabs = {
  DIAL: 'DIAL',
  CONTACTS: 'CONTACTS',
  CALL_LOG: 'CALL_LOG',
};
Object.freeze(ControlTabs);


const UIState = {
  DEFAULT: 'DEFAULT',
  CALL: 'CALL',
  SETTINGS: 'SETTINGS',
};
Object.freeze(UIState);


export default Phone;
export {
  Phone,
  UIState,
}
