import { React } from 'react';
import { Button } from 'react-bootstrap';
import { Icon } from '../rfu/ui';
import I from '../core/icons';


function CIcon(props) {
  const {
    icon, color, inverse, rotate, size, border,
    disabled, className, ...divProps
  } = props;
  const classNames = "CallIcon" + (className ? ` ${className}` : '');
  const col = inverse ? '#fff' : (color || '#000');
  const bgCol = inverse ? (color || '#000') : 'transparent';
  const style = {
    display: 'inline-block',
    color: col,
    backgroundColor: bgCol,
    transform: `rotate(${rotate || 0}deg)`,
    fontSize: `${size || 100}%`,
    border: border ? `2px solid ${color}` : 'none',
    borderRadius: '1.25em',
    width: '2.5em',
    height: '2.5em',
    padding: '0',
  };
  if (disabled) {
    style.opacity = '0.5';
  }

  return (
    <Button
      className={classNames}
      style={style}
      disabled={disabled}
      {...divProps}
    >
      <Icon src={icon} />
    </Button>
  );
}

function Dial(props) {
  return (
    <CIcon icon={I.phone} color="green" inverse={true} {...props} />
  );
}

function HangUp(props) {
  return (
    <CIcon icon={I.phone} color="red" inverse={true} rotate={270} {...props} />
  );
}


export {
  CIcon,
  Dial,
  HangUp,
};
