import * as yup from 'yup';
import { Storage } from '../rfu/storage';
import { NumID, NumIDSchema } from './sip-client';


const CONTACTS_STORAGE_KEY = 'contacts';


// Contacts storage class
// ========================================

/**
 * Call Logging class leveragging IndexedDB storage.
 *
 * @param {Object} options
 * @param {function} options.eventListener
 */
class ContactList extends Storage {
  constructor({ listener }) {
    const transformer = (stored) => ({
      timestamp: stored.timestamp,
      contacts: stored.contacts.map(c => new NumID(c.num, c.name)),
    });

    super({
      name: 'Contacts', storageKey: CONTACTS_STORAGE_KEY,
      schema: contactsSchema, transformer, listener,
      defaultContent: {timestamp: null, contacts: []},
    })
  }
}


const contactsSchema = yup.object().shape({
  timestamp: yup.number().required(),
  contacts: yup.array().of(NumIDSchema).required(),
});


export {
  ContactList,
  CONTACTS_STORAGE_KEY,
};
