import { React, useContext, useEffect, useRef, useState } from 'react';
import PT from 'prop-types';
import { useFetch } from '../rfu/requests';
import { Loader } from '../rfu/ui';
import { SearchInput } from '../rfu/search';
import { toAscii } from '../rfu/i18n';
import { fetchAuthHeaders } from '../core/auth';
import { AuthContext } from '../core/context';
import UA from '../core/urls_api';
import S from '../core/strings';
import { NumID } from '../voip/sip-client';
import { StorageEvent } from '../rfu/storage';
import { ContactList } from '../voip/contacts';
import * as CI from './CallIcons';


/**
 * Display user contacts.
 *
 * @param {object} props
 * @param {function} props.call
 * @param {number} props.contactsTStamp
 */
function Contacts(props) {
  const auth = useContext(AuthContext);
  const headers = fetchAuthHeaders(auth.user);

  const [search, setSearch] = useState('');
  const [contactList, setContactList] = useState([]);
  const contactStore = useRef(null);
  const [loaded, setLoaded] = useState(false);
  const mountRef = useRef(false);

  // prepare contact list
  // ========================================

  const updateContactList = (contacts) => {
    setContactList(contacts.map(({name, num}) => ({
      name, num,
      namesAscii: toAscii(name).split(' ').map(n => n.toLowerCase()),
    })));
    setLoaded(true);
  }

  const saveContactsCache = (contacts) => {
    if (!contactStore.current) { return; }
    /** @type {ContactList} */
    const cs = contactStore.current;
    cs.set({
      timestamp: props.contactsTStamp,
      contacts: contacts.map(c => new NumID(c.num, c.name)),
    });
    cs.save();
  };

  const contactStoreListener = (event, value) => {
    /** @type {ContactList} */
    const cs = contactStore.current;
    if (event === StorageEvent.LOADED) {
      updateContactList(cs.content.contacts);
    };
  };

  const { status, error, doFetch } = useFetch(
    UA.voip_phone_contacts, { headers }, {
      execute: false,
      onResponse: ({status, response}) => {
        const conList = status && response instanceof Array ? response : [];
        if (conList.length) {
          const contacts = (conList).map(
            ({name, number}) => ({name, num: number})).filter(c => c.num);
          updateContactList(contacts);
          saveContactsCache(contacts);
        }
      }
    }
  );

  useEffect(() => { if (!mountRef.current) {
    mountRef.current = true;
    contactStore.current = new ContactList({ listener: contactStoreListener });
  }});

  useEffect(() => {
    /** @type {ContactList} */
    const cs = contactStore.current;
    const storeTStamp = cs.content.timestamp;
    if (
      !storeTStamp
        || (storeTStamp && storeTStamp < props.contactsTStamp)
    ) {
      // prevent the effect running in cycle on fetch fails
      cs.content.timestamp = props.contactsTStamp;
      doFetch();
    }
  });

  // call number action
  // ========================================

  const call = (contact) => {
    const numID = new NumID(contact.num, contact.name);
    props.call(numID);
  };

  // render component
  // ========================================

  const contactRender = (c, idx) => (
    <div key={idx} className="Contacts__item">
      <div className="Contacts__item__contact">
        <div className="Contacts__item__name">
          {c.name}
        </div>
        <div className="Contacts__item__number">
          {c.num}
        </div>
      </div>
      <div className="Contacts__item__callIcon">
        <CI.Dial className="dimmed" onClick={() => call(c)} />
      </div>
    </div>
  );

  const contactsRendered = (
    contactList
      .filter(contactMatches(search))
      .slice(0, 100)            // debug filter only first 100 items
      .map(contactRender)
  );

  return (
    <div className="Contacts">
      <SearchInput
        value={search} onChange={s => setSearch(s)}
        placeholder={S.contactSearch}
      />
      <Loader loadStatus={loaded || status} loadError={error}>
        {contactsRendered}
      </Loader>
    </div>
  )
}
Contacts.propTypes = {
  call: PT.func.isRequired,
  contactsTStamp: PT.number.isRequired,
};


function contactMatches(search) {
  const searches = toAscii(search).toLowerCase().split(' ').filter(m => m);

  const isMatch = (names, search) => Boolean(
    names.map(n => ~n.indexOf(search)).filter(m => m).length);

  const isFound = (matches) => Boolean(
    matches.filter(m => m).length === searches.length);

  return (contact) => {
    const names = contact.namesAscii.concat(contact.num);
    return isFound(searches.map(s => isMatch(names, s)));
  };
}


export default Contacts;
