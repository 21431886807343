/*global hybridWebViewHost*/
/***
 * Notification and Push utilities.
 */


/***
 * PUSH related utilities
 */

const BACKEND_URL = process.env.REACT_APP_TIS_HOST;
const SW_BASIC_AUTH_KEY = 'swBasicAuth';
const PUSH_SUB_ADD_URL = `${BACKEND_URL}/api/push/subscriptions/add/`;
const PUSH_SUB_REMOVE_URL = `${BACKEND_URL}/api/push/subscriptions/remove/`;


function clearAuth() {
  localStorage.removeItem(SW_BASIC_AUTH_KEY);
};


/**
 * Post mesage to window.
 *
 * Enables communication with window object (app wrapper).
 */
async function postMsg(type, message) {
  const msg = JSON.stringify({"MessageType": 0, "MessageContent": {type, message}});
  console.log('[postMessage]', msg);

  if (window.chrome && window.chrome.webview) {
    // Windows WebView2
    window.chrome.webview.postMessage(msg);
  }
  else if (window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.webwindowinterop) {
    // iOS and MacCatalyst WKWebView
    window.webkit.messageHandlers.webwindowinterop.postMessage(msg);
  }
  else if (typeof hybridWebViewHost != 'undefined') {
    // Android WebView
    hybridWebViewHost.sendMessage(msg);
  }
}


/**
 * urlB64ToUint8Array -- base64 public key to Array buffer
 *
 * Array buffer is needed by the subscription option.
 */
function urlB64ToUint8Array(base64String) {
  const padding = "=".repeat((4 - (base64String.length % 4)) % 4);
  const base64 = (base64String + padding)
        .replace(/-/g, '+')
        .replace(/_/g, '/');
  const rawData = atob(base64);
  const outputArray = new Uint8Array(rawData.length);
  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
};


/**
 * Save subcription to backend.
 */
async function subscriptionSave(subscription) {
  const headers = {'Content-Type': 'application/json'}
  const basicAuth = localStorage.getItem(SW_BASIC_AUTH_KEY);
  if (basicAuth) headers.Authorization = basicAuth;
  // send subscription request with Basic HTTP Auth
  await fetch(PUSH_SUB_ADD_URL, {
    method: "post", headers,
    body: JSON.stringify(subscription)
  });
};


/**
 * Register service worker for push notifications.
 *
 * @param {ServiceWorkerRegistration} sw
 * @param {string} vapidAppKey
 */
async function pushRegister(sw, vapidAppKey) {
  try {
    const subscription = await sw.pushManager.subscribe({
      userVisibleOnly: true,
      applicationServerKey: urlB64ToUint8Array(vapidAppKey),
    });
    await subscriptionSave(subscription);
  } catch (err) {
    console.error('NOTIF: PUSH subscription activation error:', err);
    pushStop(vapidAppKey);
    throw err;
  }
}


/**
 * Get user permission to display notifications.
 */
async function notificationPermissionRequest() {
  const permission = await window.Notification.requestPermission();
  // value of permission can be 'granted', 'default', 'denied'
  // granted: user has accepted the request
  // default: user has dismissed the notification permission popup by clicking on x
  // denied: user has denied the request.
  if (permission !== 'granted') {
    throw new Error('Permission not granted for Notification');
  }
};


/**
 * Check system capability for push notifications.
 *
 * @param {string} vapidAppKey
 */
async function pushCapabilityCheck(vapidAppKey) {
  if (!vapidAppKey) {
    throw new Error('Missing VAPID application key')
  }
  if (!("serviceWorker" in navigator)) {
    throw new Error("No Service Worker support!");
  }
  if (!("PushManager" in window)) {
    throw new Error("No Push API Support!");
  }
  const sw = await navigator.serviceWorker.getRegistration();
  if (!sw) {
    throw new Error('Active service worker missing!');
  }

};


/**
 * Initiate push notification service.
 *
 * @param {string} vapidAppKey
 * @param {Object} options
 * @param {string} options.basicAuth
 * @return {boolean}
 */
async function pushStart(vapidAppKey, options={}) {
  console.debug('NOTIF: Initialising push');
  const basicAuth = options.basicAuth;
  if (basicAuth) localStorage.setItem(SW_BASIC_AUTH_KEY, basicAuth);
  let success = false;
  try {
    await pushCapabilityCheck(vapidAppKey);
  } catch (error) {
    console.debug(error);
    return;
  }
  try {
    // cancel existing subscriptions
    await pushStop(vapidAppKey, {basicAuthPersist: true, ...options});
    console.debug('NOTIF: Checking permissions');
    await notificationPermissionRequest();
    const sw = await navigator.serviceWorker.getRegistration();
    if (!sw) throw new Error('Missing activated service worker.');
    await pushRegister(sw, vapidAppKey);
    success = true;
  } catch (error) {
    console.error(error);
  }
  clearAuth()
  return success;
};


/**
 * Terminate push notification service.
 *
 * @param {Object} options
 * @param {string} options.basicAuth
 * @param {boolean} options.basicAuthPersist
 * @return {boolean}
 */
async function pushStop(vapidAppKey, options={}) {
  console.debug('NOTIF: Stopping push');
  const basicAuth = options.basicAuth;
  if (basicAuth) localStorage.setItem(SW_BASIC_AUTH_KEY, basicAuth);
  let success = false;
  try {
    await pushCapabilityCheck(vapidAppKey);
  } catch (error) {
    console.debug(error);
    return;
  }
  try {
    const sw = await navigator.serviceWorker.getRegistration();
    let subscription = await sw.pushManager.getSubscription();
    if (subscription) {
      const result = await subscription.unsubscribe();
      if (result) {
        const headers = {'Content-Type': 'application/json' };
        if (basicAuth) headers.Authorization = basicAuth;
        await fetch(PUSH_SUB_REMOVE_URL, {
          method: "post", headers,
          body: JSON.stringify(subscription)
        });
      }
    }
    success = true;
  } catch (error) {
    console.error(error);
  }
  if (!options.basicAuthPersist) {
    clearAuth();
  }
  return success;
}


/**
 * @return {boolean}
 */
async function pushIsActive() {
  const registrations = await navigator.serviceWorker.getRegistrations();
  if (window.Notification.permission === 'granted' && registrations.length) {
    for (const reg of registrations) {
      if (await reg.pushManager.getSubscription()) {
        return true;
      }
    }
  }
  return false;
}


/**
 * @param {string} vapidAppKey
 * @param {Object} options
 * @param {string} options.basicAuth
 * @return {boolean}
 */
async function pushToggle(vapidAppKey, options={}) {
  const active = await pushIsActive();
  console.debug('NOTIF: Current PUSH activation state', active);
  await (active ? pushStop : pushStart)(vapidAppKey, options);
  console.debug('NOTIF: New PUSH activation state', !active);
  return !active;
}


export {
  clearAuth,
  postMsg,
  pushCapabilityCheck,
  pushIsActive,
  pushStart,
  pushStop,
  pushToggle,
  BACKEND_URL,
  SW_BASIC_AUTH_KEY,
  PUSH_SUB_ADD_URL,
  PUSH_SUB_REMOVE_URL,
}
