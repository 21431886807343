import {
  UserAgent, Inviter, Registerer, RegistererState, SessionState
} from 'sip.js';
import * as yup from 'yup';


// Client
// ========================================

/**
 * SIP client options.
 */
const isFunc = { name: 'isFunc',   test: value => typeof value === 'function' };
const isDomEl = { name: 'isDomEl',   test: value => value instanceof HTMLElement };
const sipClientOptsSchema = yup.object().shape({
  serverUrl: yup.string().required(),
  serverName: yup.string().required(),
  login: yup.object().shape({
    username: yup.string().required(),
    password: yup.string(),
  }),
  streamLocal: yup.mixed().test(isDomEl).required(),
  streamRemote: yup.mixed().test(isDomEl).required(),
  eventListeners: yup.object().shape({
    onState: yup.mixed().test(isFunc).default(_ => null),
    onMessage: yup.mixed().test(isFunc).default(_ => null),
    onConnecting: yup.mixed().test(isFunc).default(_ => null),
    onIdle: yup.mixed().test(isFunc).default(_ => null),
    onOutgoing: yup.mixed().test(isFunc).default(_ => null),
    onIncoming: yup.mixed().test(isFunc).default(_ => null),
    onCall: yup.mixed().test(isFunc).default(_ => null),
    onNumsOnHold: yup.mixed().test(isFunc).default(_ => null),
    onHangup: yup.mixed().test(isFunc).default(_ => null),
    onDisconnect: yup.mixed().test(isFunc).default(_ => null),
  }),
});


/**
 * SIP client.
 */
class SipClient {
  /**
   * Client initialisation.
   */
  constructor(options) {
    const {
      serverUrl, serverName, login, eventListeners, streamLocal, streamRemote
    } = sipClientOptsSchema.cast(options);
    this.serverUrl = serverUrl;
    this.serverName = serverName;
    this.login = login;
    this.streamLocal = streamLocal;
    this.streamRemote = streamRemote;
    this.eventListeners = eventListeners;

    this.userName = null;
    this.agent = null;
    this.registerer = null;
    this.session = null;
    this.account = null;
    this.inviteOptions = null;
    this.numID = null;
    this.callsOnHold = [];

    this.state = CallState.OFF;   // off, idle, outgoing, incoming, call
    this.autoReconnect = false;
    this.connectionFailures = 0;

    // main init procedure
    this.init();
  }

  init() {
    this.inviteOptions = {
      sessionDescriptionHandlerOptions: {
        constraints: { audio: true, video: false }
      }
    };
    this.register();
  }

  setState(state) {
    this.state = state;
    this.eventListeners.onState(this.userName, state);
  }

  /***
   * SIP UserAgent Registration.
   */
  async register() {
    await this.unregister();
    this.autoReconnect = true;
    this.initAccount();
    if (!this.account) {
      return;
    }

    // initialise agent
    this.agent = new UserAgent({
      authorizationUsername: this.account.username,
      authorizationPassword: this.account.password,
      uri: this.account.uri,
      transportOptions: {
        server: this.serverUrl,
        keepAliveInterval: 10,    // seconds
        onDisconnect: () => this.reconnect(),
      },
    });
    // add agent events
    this.agent.delegate = {
      onInvite: async (invitation) => {
        const numID = NumID.fromInvite(invitation);
        await this.sessionOnInvite(invitation, CallState.INCOMING, numID);
      }
    };

    // set registerer
    this.registerer = new Registerer(this.agent);
    this.registerer.stateChange.addListener((newState) => {
      switch (newState) {
        case RegistererState.Registered:
          this.connectionFailures = 0;
          this.setState(CallState.IDLE);
          this.eventListeners.onIdle(this.userName);
          break;
        case RegistererState.Unregistered:
          this.setState(CallState.OFF);
          this.eventListeners.onDisconnect(this.userName);
          console.warn('SIP client unregistered:');
          this.reconnect();
          break;
        default:
          break;
      }
    });

    this.setState(CallState.CONNECTING);
    this.eventListeners.onConnecting(this.userName);
    try {
      await this.agent.start();
      await this.registerer.register();
    } catch (err) {
      console.error('Register ERROR', err);
      await this.unregister(this.account.username);
    }
  }


  reconnect() {
    if (this.autoReconnect && this.connectionFailures < 4) {
      console.warn('Reconnecting...');
      setTimeout(() => {
        this.register();
        // listeners
        this.setState(CallState.CONNECTING);
        this.eventListeners.onConnecting(this.userName);
      }, this.connectionFailures * 2000);
      this.connectionFailures++;
    }
  }


  async unregister() {
    this.eventListeners.onDisconnect(this.userName);
    await this.hangup();
    try {
      if (this.agent && this.agent.isConnected()) {
        await this.agent.stop();
      }
    } catch (err) {
      console.error('Unregister ERROR', err);
    }
    this.agent = null;
    this.registerer = null;
    this.setState(CallState.OFF);
  }


  async call(numID) {
    const target = UserAgent.makeURI(`sip:${numID.num}@${this.serverName}`);
    if (!target || !this.agent) { return; }

    const inviter = new Inviter(this.agent, target);
    await this.sessionOnInvite(inviter, CallState.OUTGOING, numID);
    try {
      await inviter.invite();
    } catch (error) {
      console.error('ERROR', error);
      this.sessionOnTerminated(inviter);
    }
  }


  async hangup() {
    const sess = this.session;
    if (!sess) { return; }

    switch (sess.state) {
      case SessionState.Establishing:
        sess.cancel && await sess.cancel();
        break;
      case SessionState.Established:
        await sess.bye();
        break;
      default:
        break;
    }
    this.eventListeners.onHangup(this.userName);
  }


  accept() {
    if (this.session) {
      this.session.accept(this.inviteOptions);
    }
  }


  reject() {
    if (this.session) {
      this.session.reject();
    }
  }


  /**
   * Setup new session.
   *
   * @param {Inviter} session
   * @param {string} state
   * @param {NumID} numID
   */
  async sessionOnInvite(session, state, numID) {
    // no current call
    if (!this.session) {
      this.session = session;
      this.numID = numID;
    }
    // when call active put session on hold
    else {
      this.callsOnHold.push({session, numID});
      // we do not change state in this case
      state = this.state;
      this.eventListeners.onNumsOnHold(
        this.userName,
        this.callsOnHold.map(c => c.numID));
    }
    this.setState(state);

    // listen to session events
    session.stateChange.addListener((newState) => {
      switch (newState) {
        case SessionState.Established:
          this.initMedia();
          this.setState(CallState.CALL);
          this.eventListeners.onCall(this.userName, numID);
          break;
        case SessionState.Terminated:
          this.sessionOnTerminated(session);
          break;
        default:
          break;
      }
    });

    if (state === CallState.INCOMING) {
      this.eventListeners.onState(this.userName, CallState.INCOMING);
      this.eventListeners.onIncoming(this.userName, numID);
    } else if (state === CallState.OUTGOING) {
      this.eventListeners.onState(this.userName, CallState.OUTGOING);
      this.eventListeners.onOutgoing(this.userName, numID);
    }
  }


  initMedia() {
    // initialize media
    if (!this.session) { return; }

    const pc = this.session.sessionDescriptionHandler.peerConnection;

    // remote tracks
    const remoteStream = new MediaStream();
    pc.getReceivers().forEach((receiver) => {
      if (receiver.track) {
        remoteStream.addTrack(receiver.track);
      }
    });
    this.streamRemote.srcObject = remoteStream;
    this.streamRemote.play();

    // local tracks
    const localStream = new MediaStream();
    pc.getSenders().forEach((sender) => {
      if (sender.track) {
        localStream.addTrack(sender.track);
      }
    });
    this.streamLocal.srcObject = localStream;
    this.streamLocal.play();
  }


  sessionOnTerminated(session) {
    let stopAudio = false;
    let nextSession = this.session;
    let nextNumID = null;
    let onHoldExist = Boolean(this.callsOnHold.length);
    let terminateMainSession = session === this.session;

    // cancel active session
    if (terminateMainSession) {
      stopAudio = true;
      // render first onHold as incoming
      if (onHoldExist) {
        const {session: firstWaiting, numID} = this.callsOnHold.shift();
        nextSession = firstWaiting;
        nextNumID = numID;
        this.setState(CallState.INCOMING);
        this.eventListeners.onIncoming(this.userName, numID);
        this.eventListeners.onNumsOnHold(
          this.userName,
          this.callsOnHold.map(c => c.numID));
      } else {
        nextSession = null;
        this.setState(CallState.IDLE);
        this.eventListeners.onIdle(this.userName);
      }
    }

    // or cancel onHold session
    else if (onHoldExist) {
      let termSessIdx = null;
      // try to find session in onHold
      this.callsOnHold.forEach((call, idx) => {
        if (call.session === session) {
          termSessIdx = idx;
        }
      });
      // remove terminated session if found
      if (termSessIdx !== null) {
        this.callsOnHold.splice(termSessIdx, 1);
      }
      this.setState(CallState.IDLE);
      this.eventListeners.onIdle(this.userName);
    }

    if (stopAudio) {
      this.streamLocal.srcObject = null;
      this.streamLocal.pause();
      this.streamRemote.srcObject = null;
      this.streamRemote.pause();
    }

    this.session = nextSession;
    this.numID = nextNumID;
  };


  /**
   * Initialize account.
   */
  initAccount() {
    const { username, password } = this.login;
    this.account = {
      username, password,
      uri: UserAgent.makeURI(`sip:${username}@${this.serverName}`),
    };
    this.userName = username;
  }

  /**
   * Switch account.
   *
   * @param {string} username
   * @param {string} password
   */
  switchAccount(username, password) {
    // when switching accounts we do not want to initiate auto-reconnection
    this.autoReconnect = false;
    this.login = { username, password };
    this.register();
  }


  // Information utilities
  // ========================================

  isInCall() {
    return Boolean(~[
      CallState.INCOMING,
      CallState.OUTGOING,
      CallState.CALL,
    ].indexOf(this.state));
  }
}



// Call State ENUM
// ========================================

const CallState = {
  OFF: 'off',
  CONNECTING: 'connecting',
  IDLE: 'idle',
  INCOMING: 'incoming',
  OUTGOING: 'outgoing',
  CALL: 'call',
};
Object.freeze(CallState);


// Message types
const MessageType = {
  INFO: 'info',
  WARNING: 'warning',
  ERROR: 'error',
};
Object.freeze(MessageType);


// Number ID
// ========================================

class NumID {
  /**
   * @param {string} num
   * @param {string} name
   */
  constructor(num, name) {
    this.num = num;
    this.name = name || num;
  }

  /**
   * @param {Object} invitation
   * @return {NumID}
   */
  static fromInvite(invitation) {
    const identity = invitation.remoteIdentity;
    return new NumID(identity.uri.normal.user, identity.displayName);
  }
}


const NumIDSchema = yup.object().shape({
  num: yup.string().required(),
  name: yup.string(),
});



export {
  SipClient,
  CallState,
  MessageType,
  NumID,
  NumIDSchema,
}
