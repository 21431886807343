/**
 * Validate number respecting length.
 *
 * @param {string} num
 * @returns {boolean}
 */
function isValidNum(num) {
  if (num && isValidNumPart(num) && num.length >= 3) {
    return true;
  }
  return false
};


/**
 * Validate number contents.
 *
 * @param {string} num
 * @returns {boolean}
 */
function isValidNumPart(num) {
  if (typeof num !== 'string') {
    return false;
  }
  for (let i = 0; i < num.length; i++) {
    const ch = num[i];
    const validChars = '0123456789' + (i === 0 ? '+' : '');
    if (!~validChars.indexOf(ch)) {
      return false;
    }
  }
  return true;
}


export {
  isValidNum,
  isValidNumPart,
}
