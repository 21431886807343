/***
 * ENVIRONMENT UTILITIES
 */


/**
 * Return environment variable based on availability.
 */
function envVar(varName, defaultValue) {
  const winEnv = window.env || {};
  if (process.env.NODE_ENV !== 'development' && winEnv[varName] !== undefined) {
    return winEnv[varName];
  }
  if (process.env[varName] === undefined && defaultValue !== undefined) {
    return defaultValue;
  }
  return process.env[varName];
}


/**
 * Return environment variable as boolean.
 */
function envBool(varName, defaultValue) {
  return envVar(varName, defaultValue ? 'true' : 'false').toLowerCase() === 'true';
}


export {
  envVar,
  envBool,
};
