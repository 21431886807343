import React, { useState, useEffect, useRef, useCallback } from 'react';
import PT from 'prop-types';
import { Alert } from 'react-bootstrap';
import * as yup from 'yup';
import S from './strings';
import { Icon } from './ui';
import I from './icons';
import { FIFOPool } from './concurrency';
import CSS from './requests.module.scss';


// REQUEST, FETCH
// ========================================

/**
 * :js:func:`useFetch` optional parameter defaults
 *
 * Object holding default parameter values.  Used to validate provided params
 * against.
 *
 * .. code-block:: jsx
 *
 *   {
 *      execute: true,
 *      pool: null,        // FIFOPool instance
 *      onResponse: null,  // response callback with status, error, response
 *   }
 *
 * @namespace
 * @property {boolean} execute
 * @property {FIFOPool} pool
 * @property {function} onResponse
 */
const useFetchParams = {
  execute: true,
  pool: null,                   // FIFOPool instance
  onResponse: null,             // response callback with status, error, resp.
};
Object.freeze(useFetchParams);


/**
 * :js:func:`useFetch` response object.
 * @class
 * @constructor
 */
class UseFetchInstance {
  constructor(status, response, error, doFetch, doAbort) {
    /**
     * Status
     * @type {boolean|null}
     */
    this.status = status;
    /**
     * API response as a JSON object.
     * @type {object|null}
     */
    this.response = response;
    /**
     * Error, instance of :js:class:`UseFetchResponseError`
     * @type {null|UseFetchResponseError}
     */
    this.error = error;
    /**
     * Fetch callback to re-execute the request with parameters.
     *
     * ``options`` and ``params`` when set override corresponding attributes
     * passed to the :js:func:`useFetch` hook.
     *
     * .. note::
     *
     *    In a special case you might want to send the request to a *different
     *    URL* than that provided to the :js:func:`useFetch` hook.  This can
     *    be done via setting the ``options.url`` attribute.
     *
     * @type {function}
     * @param {Object} [options] - optional ``window.fetch`` options
     * @param {useFetchParams} [params] - optional parameters
     * @property {boolean} params.execute - execute immediatelly (default=true)
     * @property {function} params.onResponse - callback receiving status,
     *   error and response
     */
    this.doFetch = (options=null, params=null) => doFetch(options, params);
    /**
     * Abort callback to abort current request.
     * @type {function}
     */
    this.doAbort = () => doAbort();
  }
}


/**
 * :js:func:`useFetch` response object.
 *
 * @class
 * @constructor
 */
class UseFetchResponseError {
  constructor({code, type, detail}) {
    /**
     * Error code
     * @type {number}
     */
    this.code = code;
    /**
     * Error type
     * @type {string}
     */
    this.type = type;
    /**
     * Error detail
     * @type {string}
     */
    this.detail = detail;
  }
}


/**
 * React hook for HTTP requests.
 *
 * Accepts required request ``url`` with optional ``options`` and ``params``.
 * The structure of ``options`` is identical with ``window.fetch`` options.
 *
 * @param {string} url - request URL
 * @param {Object} [options] - optional ``window.fetch`` options
 * @param {useFetchParams} [params] - optional parameters
 * @property {boolean} params.execute - execute immediatelly (default=true)
 * @property {function} params.onResponse - callback receiving status, error and
 *   response
 * @property {FIFOPool} pool - use provided :js:class:`FIFOPool` instance
 * @returns {UseFetchInstance} :js:class:`UseFetchInstance` object
 */
function useFetch(url, options=null, params=null) {

  // INITIATE THE HOOK
  // ========================================

  // process params
  params = Object.assign({...useFetchParams}, params || {});
  const execute = params.execute;
  const onResponse = params.onResponse || (() => null);
  const pool = useRef(params.pool || new FIFOPool());
  const [poolTicket, setPoolTicket] = useState(null);

  // set hook internals
  const urlRef = useRef(url);
  const optRef = useRef(options || {});
  const execLockRef = useRef(false);
  const abortRef = useRef(null);
  const mountRef = useRef(false);
  const [status, setStatus] = useState(null);
  const [error, setError] = useState(null);
  const [response, setResponse] = useState(null);


  // PROCESS INPUTS AND PARAMS
  // ========================================

  const cleanOpts = useCallback((token) => {
    let op = optRef.current;
    op.headers = op.headers || {
      'Content-Type': 'application/json',
    }
    op.mode = 'cors';
    if (op.body && op.headers['Content-Type'] === 'application/json'
        && op.body instanceof Object) {
      op.body = JSON.stringify(op.body);
    }
  }, [optRef]);

  const updateOptions = (options) => {
    // if not execution lock
    if (!execLockRef.current && mountRef.current) {
      if (options) {
        const {url: newUrl, ...newOpts} = options;
        if (newUrl) {
          urlRef.current = newUrl;
        }
        if (Object.keys(newOpts).length) {
          optRef.current = newOpts;
        }
      }
    }
  };


  // RUN ACTUAL FETCH
  // ========================================

  const fetchData = useCallback(async () => {
    // override useFetch state variables, but leads to a much cleaner code on
    // returns
    let status = null;
    let error = null;
    let response = null;

    setStatus(status);
    const method = (optRef.current.method || 'GET').toUpperCase();

    cleanOpts();
    try {
      const fetchOptions = {...optRef.current || {}};
      abortRef.current = new AbortController();
      fetchOptions.signal = abortRef.current.signal;
      _FETCH_PARAM_TRANSFORMER.processor(urlRef.current, fetchOptions);
      const fetchResponse = await fetch(urlRef.current, fetchOptions);

      // stop if the component is not mounted anymore (e.g. when user
      // navigated away before data fetching was completed)
      if (!mountRef.current) {
        return {status, error, response};
      }

      const parsedResponse = await parseFetchResponse(
        fetchResponse, method === 'DELETE'
      );
      status = parsedResponse.status;
      error = parsedResponse.error;
      response = parsedResponse.response;
      setResponse(response);
    } catch (e) {
      console.warn('useFetch error:', e);
      status = false;
      error = {
        code: 500,
        type: 'Internal Server Error',
        detail: 'Server failed to return a valid response.',
      };
    }
    abortRef.current = null;
    setStatus(status);
    setError(error);
    return {status, error, response};
  }, [cleanOpts]);


  // EFFECTS
  // ========================================

  // fetch on component mount if set
  useEffect(() => {
    if (!mountRef.current) {
      mountRef.current = true;
      if (execute) {
        doFetch(optRef.current);
      }
    }
  });

  // cleanups on unmount
  useEffect(() => () => mountRef.current = false, []);
  useEffect(() => () =>
    poolTicket ? pool.current.finished(poolTicket) : null, [poolTicket]);

  // set ticket finished
  useEffect(() => {
    if (poolTicket !== null && execLockRef.current && status !== null) {
      // release execution lock
      execLockRef.current = false;
      pool.current.finished(poolTicket);
      setPoolTicket(null);
    }
  }, [setPoolTicket, poolTicket, status]);


  // REQUEST PROCESSING
  // ========================================

  // launch request using pool
  const doFetch = async (options, params) => {
    params = params || {};
    if (execLockRef.current) {
      return;
    }
    updateOptions(options);
    if (!urlRef.current) {
      setStatus(false);
      return;
    }
    // set execution lock
    execLockRef.current = true;
    const onResp = params.onResponse || onResponse;
    const ticket = pool.current.register(async () => {
      const statusErrorResponse = await fetchData();
      // execute custom callback with the response;
      onResp({...statusErrorResponse});
    });
    setPoolTicket(ticket);
  };

  const doAbort = async () => {
    // abort a currently running fetch request
    if (abortRef.current) {
      abortRef.current.abort();
    }
    // abort a waiting pool ticket
    else if (poolTicket) {
      pool.current.finished(poolTicket);
      setPoolTicket(null);
      setStatus(false);
      setError(S.aborted);
      setResponse(null);
    }
  }

  // RETURN RESULTS
  // ========================================
  return new UseFetchInstance(status, response, error, doFetch, doAbort);
};


async function parseFetchResponse(fetchResponse, isDelete=false) {
  let status = null;
  let error = null;
  let response = null;
  response = (
    fetchResponse.status < 500 && !isDelete
  ) ? await fetchResponse.json() : {};

  if (fetchResponse.ok) {
    status = true;
  } else {
    status = false;
    let detail = response.detail || 'Resource fetching failed.';
    if (detail instanceof Object) {
      detail = JSON.stringify(detail, null, 2);
    }
    error = new UseFetchResponseError({
      code: response.code || fetchResponse.status,
      type: fetchResponse.statusText,
      detail: detail,
    });
  }
  return { status, error, response };
}


/**
 * Combine multiple request states into one.
 *
 * Any false leads results into false, null and true leads to null.
 *
 * @param {...boolean|null} connStatus - connection status results
 * @return {boolean|null} resulting status
 */
function reduceFetchStatus(...connStatus) {
  return connStatus.reduce((a, b) =>
    (a === false || b === false) ? false : a && b
  );
}

/**
 * Adds GET parameter to request related joins on API be included in returned
 * objects.
 *
 * Example:
 *
 * .. code-block:: jsx
 *
 *   const url = 'https://get.some.com/objects?someParam=value';
 *   const urlWithJoins = withJoins(url, 2);
 *   // urlWithJoins === 'https://get.some.com/objects?someParam=value&_rel_joins=2'
 *
 * For information about why this is useful see :ref:`core-api-related-objects`.
 *
 * @param {string} url - URL
 * @param {number} joins - number of joins for related objects.
 * @return {string} - resulting URL with rel_joins parameter.
 */
function withJoins(url, joins) {
  const u = new URL(url);
  u.searchParams.set('_rel_joins', joins);
  return u.toString();
}

/**
 * Component to handle content loading.
 *
 * Displays loading status and content when ready or error when loading fails.
 * ``connStatus`` and ``connError`` can be passed as ``Array`` when processing
 * status and error from multiple sources/requests.
 *
 * When ``status === null`` a loader icon is rendered.
 *
 * When ``status === false`` an error is rendered.
 *
 * When ``status === true`` the content in ``props.children`` is rendered.
 *
 * .. note::
 *
 *    The loader *does not* prevent React from initialising ``props.children``
 *    components (i.e. running their inner code), it only decides whether to
 *    render them into the DOM.  Thus, if some ``props.children`` component
 *    depends on ``status === true`` to render correctly, it has to be dealt
 *    with in the Loader's parent component, like so:
 *
 *    .. code-block:: jsx
 *
 *      <Loader connStatus={status} connError={error}>
 *        { status ? <DoesNotCrashWhenStatusIsNullOrFalse /> : null}
 *      </Loader>
 *
 * Uses :js:func:`reduceFetchStatus` to calculate resulting ``status``.
 *
 * @param {Object} props - component ``props`` object
 * @param {boolean|boolean[]|null} connStatus - response status
 * @param {UseFetchResponseError|UseFetchResponseError[]|null} [connError] - *
 *   response status
 * @param {...*} divProps.* - other component properties, passed to the
 *   wrapper ``div`` element of ``props.children``.
 */
function Loader(props) {
  let {connStatus, connError, ...divProps} = props;
  if (!(connError instanceof Array)) {
    connError = [connError];
  }
  if (!(connStatus instanceof Array)) {
    connStatus = [connStatus];
  }
  const status = reduceFetchStatus(...connStatus);

  let content;
  if (!status) {
    // proces errors
    if (status === false) {
      content = connError.map((cErr, idx) => {
        let errMsg;
        let errType;
        if (typeof cErr === 'string') {
          errMsg = cErr;
          errType = S.loadingDataFailed;
        } else {
          errMsg = (cErr && cErr.detail) || S.loadingDataFailedError;
          errType = cErr && cErr.type && cErr.code
            ? (`${cErr.code}: ${cErr.type}`)
            : (S.loadingDataFailed);
        }
        if (!errType && !errMsg) {
          return '';
        }
        return (
          <div key={idx} className="alert">
            <Alert variant="danger">
              <Alert.Heading>{errType}</Alert.Heading>
              <pre>{errMsg}</pre>
            </Alert>
          </div>
        );
      });
    }

    // process loading
    else {
      content = (
        <div className="alert loading">
          <Icon className={CSS.spinner} src={I.spinner} />
        </div>
      );
    }
  } else {
    content = props.children;
  }
  return (
    <div {...divProps}>
      {content}
    </div>
  );
}
Loader.propTypes = {
  connStatus: PT.oneOfType([
    PT.bool, PT.arrayOf(PT.bool)
  ]),
  connError: PT.oneOfType([
    PT.string, PT.object, PT.arrayOf(PT.string), PT.arrayOf(PT.object)
  ]),
};



/**
 * Display a full-width loading "stripe" at the top of the parent component.
 */
function LoadingProgress(props) {
  const progress = Math.min(Math.max(props.progress || 0), 100);
  const placement = props.placement || 'top';
  const isDone = progress === 100;

  // do not render when fully loaded
  if (isDone && !props.displayDone) {
    return '';
  }

  // render loading progress
  const style = { position: 'absolute', width: '100%', left: '0'};
  style[placement] = 0;
  const styleStripe = { position: 'relative', width: `${progress}%` };
  return (
    <div className={`LoadingProgress ${isDone ? 'done' : ''}`} style={style}>
      <div className="LoadingProgress__stripe" style={styleStripe}></div>
    </div>
  );
}
LoadingProgress.propTypes = {
  progress: PT.number.isRequired,
  placement: PT.oneOf(['top', 'bottom']),
  displayDone: PT.bool,
};


// REQUEST AUTHENTICATION
// ========================================

const _FETCH_PARAM_TRANSFORMER_DEFAULT = (url, options) => null;
const _FETCH_PARAM_TRANSFORMER = {
  timestamp: null,
  processor: _FETCH_PARAM_TRANSFORMER_DEFAULT,
};

/**
 * Set processor for useFetch parameters,
 *
 * Use to add/transform window.fetch request options used by
 * :js:func:`useFetch`.
 *
 * @param {function} func - function receiving URL and options as parameters.
 */
function setFetchParamTransformer(func) {
  if (!func) {
    _FETCH_PARAM_TRANSFORMER.processor = _FETCH_PARAM_TRANSFORMER_DEFAULT;
  } else {
    _FETCH_PARAM_TRANSFORMER.processor = func;
  }
  _FETCH_PARAM_TRANSFORMER.timestamp = Date.now();
}


// INSTANCE FUNCTIONS
// ========================================

/**
 * Return value of a field from instance schema.
 *
 * @param {object} instance - API instance JSON
 * @param {string|Array} field - field as string or a sequence for related field
 * @return {string|number|boolean} - JSON field value
 */
function instanceFieldValue(instance, field, fullObj=false) {
  let finalInstance = instance;
  let finalField = field;

  // for related fields get the related value from joins
  if (field instanceof Array) {
    const [targetField, ...relations] = [...field].reverse();
    relations.reverse();
    // get the last relation instance
    let broken = false;
    let target = instance;
    for (let rel of relations) {
      target = (target.related_ || {})[rel];
      if (! target instanceof Object) {
        broken = true;
        break;
      }
    }
    finalField = targetField;
    if (target && !broken) {
      finalInstance = target;
    } else {
      finalInstance = null;
    }
  }

  let value = undefined;
  if (finalInstance) {
    if (fullObj) {
      value = (finalInstance.related_ || {})[finalField];
    } else {
      value = finalInstance[finalField];
    }
  }

  return value
}


// ERROR PROCESSING
// ========================================

/**
 * Return API errors in a common scheme.
 *
 * Used particularly with form operations, when field errors are resolved.
 *
 * @param {object} response - JSON response
 * @param {object} options - processing options
 * @param {boolean} options.asStrings - return as one string
 */
async function errorsUnify(response, options) {
  options = options || {};
  let errors = {};
  // process form errors
  if (await errorsFormSchema.isValid(response)) {
    errors = response.errors;
  } else if (await errorsPydanticSchema.isValid(response)) {
    response.errors.forEach(err => {
      let field = err.loc[err.loc.length - 1];
      errors[field] = errors[field] || [];
      errors[field].push({
        code: err.type, message: err.msg, field: field
      });
    });
  } else {
    errors.__all__ = [{ code: 'unknown', message: S.unknownError }];
  }

  // return errors as list of strings if requested
  if (options.asStrings) {
    const strings = [];
    (errors.__all__ || []).forEach(
      e => strings.push(`${e.code}: ${e.message}`));
    Object.keys(errors).filter(key => key !== '__all__').forEach(
      field => errors[field].forEach(
        e => strings.push(`(${field}) ${e.code}: ${e.message}`))
    );
    return strings;
  }

  // return regular errors objec
  return errors;
}


/**
 * Error schema for form CRUD operations.
 */
const errorsFormSchema = yup.object().shape({
  instance: yup.object().notRequired(),
  errors: yup.object().test(
    'is-errors', '${path} is not a valid error object', // eslint-disable-line
    async (errs, context) => {
      const erSchema = yup.array().of(yup.object().shape({
        code: yup.string().required(),
        message: yup.string().required(),
      }));
      for (let key in errs) {
        if (!await await erSchema.isValid(errs[key])) {
          return false;
        }
      }
      return true;
    }
  ).required(),
  saved: yup.boolean().required(),
  code: yup.number().required(),
  success: yup.boolean().required(),
}).required();


/**
 * General pydantic validation error schema.
 */
const errorsPydanticSchema = yup.object().shape({
  code: yup.number().required(),
  errors: yup.array().of(
    yup.object().shape({
      loc: yup.array().of(yup.string()).required(),
      msg: yup.string().required(),
      type: yup.string().required(),
    })
  ).required(),
}).required();


/**
 * Resolve the urls based on params.
 *
 * Example:
 *
 * .. code-block:: jsx
 *
 *   const schema = '/path/:one/:two/thing';
 *   const one = 'to';
 *   const two = 'some';
 *   const url = resolveURL(schema, {one, two});
 *   // url === '/path/to/tome/thing'
 *
 * @param {string} schema - URL schema
 * @param {Object} params - path parameters
 * @return {string} - resulting URL
 */
function resolveURL(schema, params) {
  params = params || {};
  for (let p in params) {
    schema = schema.replace(`:${p}`, params[p]);
  }
  return schema;
}


export {
  // hooks
  useFetch,
  parseFetchResponse,

  // components
  Loader,
  LoadingProgress,

  // utilities
  instanceFieldValue,
  reduceFetchStatus,
  resolveURL,
  setFetchParamTransformer,
  withJoins,

  // error processing
  errorsUnify,
  errorsFormSchema,
  errorsPydanticSchema,
};
