//fontawesome icons
import { faCircleArrowUp } from '@fortawesome/free-solid-svg-icons';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { faCircleArrowDown } from '@fortawesome/free-solid-svg-icons';
import {
  faPhone,
  faRotate,
  faSpinner,
  faTimes,
} from '@fortawesome/free-solid-svg-icons';


const Icons = {
  menu: faBars,
  close: faTimes,
  spinner: faSpinner,
  phone: faPhone,
  reload: faRotate,
  callIncoming: faCircleArrowDown,
  callOutgoing: faCircleArrowUp,
};

export default Icons;
