import { React } from 'react';
import * as PT from 'prop-types';
import { Icon } from '../rfu/ui';
import { NumID } from '../voip/sip-client';
import { Call, CallDirection, Log } from '../voip/call-log';
import S from '../core/strings';
import I from '../core/icons';
import * as CI from './CallIcons';


/**
 * @param {Object} props
 * @param {Log} props.callLog
 * @param {number} props.callLogTStamp
 * @param {function} props.call
 */
function CallLog(props) {
  const { callLog } = {...props};

  const calls = [];
  [...callLog.get() || []].reverse().forEach((call, idx) => {
    const uid = `${idx}__${call.timeInvited}`;
    calls.push(
      <div key={uid} className={'CallLog__item '}>
        <div className="CallLog__info">
          <CallInfoName call={call} />
          <CallInfoDetail call={call} />
        </div>
        <div className="CallLog__action">
          <CallBackAction call={call} callBack={props.call} />
        </div>
      </div>
    );
  });

  return (
    <div className="CallLog">
      { calls.length ? '' : S.noCallsHistory }
      {calls}
    </div>
  )
}
CallLog.propTypes = {
  callLog: PT.instanceOf(Log).isRequired,
  callLogTStamp: PT.number,
  call: PT.func.isRequired,
}


/**
 * @param {Object} props
 * @param {Call} props.call
 */
function CallInfoName(props) {
  const { name, num } = props.call.nameNumDirection();
  return (
    <div className="CallLog__info__name">
      <div className="name">
        {name || num}
      </div>
      <div className="number">
        {num}
      </div>
    </div>
  );
}
CallInfoDetail.propTypes = {
  call: PT.instanceOf(Call).isRequired,
};


/**
 * @param {Object} props
 * @param {Call} props.call
 */
function CallInfoDetail(props) {
  const call = props.call;
  const incoming = call.direction === CallDirection.IN;
  const accepted = Boolean(call.timeAccepted);
  const stateCls = (
    `${incoming ? 'incoming' : 'outgoing'} `
      + `${accepted ? 'accepted' : 'missed'} `
  );
  const date = new Date(call.timeInvited);
  const { min, sec, totalSeconds } = call.toMinSec();
  const duration = totalSeconds
        ? (String(min).padStart(2, '0') + ':' + String(sec).padStart(2, '0'))
        : null;
  const dateStr = date.toLocaleDateString()
  const timeStr = date.toLocaleTimeString()
  const showDate = !(dateStr === (new Date(Date.now())).toLocaleDateString());
  return (
    <div className="CallLog__info__detail">
      <div className={'CallLog__info__icon ' + stateCls}>
        <Icon src={incoming ? I.callIncoming : I.callOutgoing} />
      </div>
      <div className="CallLog__info__date">
        {showDate ? dateStr : null} {timeStr}
      </div>
      <div className="flexExpander"></div>
      <div className="CallLog__info__duration">
        {duration || '- - : - -'}
      </div>
    </div>
  );
}
CallInfoDetail.propTypes = {
  call: PT.instanceOf(Call),
};


/**
 * @param {Object} props
 * @param {Call} props.call
 * @param {function} props.callBack
 */
function CallBackAction(props) {
  const { num, name } = props.call.nameNumDirection();
  const numID = new NumID(num, name);
  return (
    <div className="CallLog__action_callback">
      <CI.Dial onClick={() => props.callBack(numID)} className="dimmed" />
    </div>
  );
}
CallBackAction.propTypes = {
  call: PT.instanceOf(Call).isRequired,
  callBack: PT.func.isRequired,
};


export default CallLog;
