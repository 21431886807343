import React, { useState, useEffect, useRef } from 'react';
import { InputGroup, Button, FormControl } from 'react-bootstrap';
import { useUID } from 'react-uid';
import { Icon } from './ui';
import I from './icons';


function SearchInput(props) {
  // render collapsed or expanded
  const {
    value, onChange, label, placeholder, className, collapsible,  ...divProps
  } = props;
  const uid = useUID();
  const [collapsed, setCollapsed] = useState(collapsible && !props.value);
  const [lastResized, setLastResized] = useState(Date.now());
  const inputRef = useRef(null);
  const loaded = useRef(false);
  const onResize = useRef();
  const windowResized = () => {
    setLastResized(Date.now());
  };

  let classes = ['SearchInput'];
  if (props.className) { classes.push(props.className); }
  if (collapsed) { classes.push('collapsed'); }

  useEffect(_ => {
    inputRef.current && inputRef.current.focus();
  }, [collapsed]);

  // apply additional effects (transparency for other elements) on state
  // update and window resize
  useEffect(_ => {
    if (!loaded.current) {
      onResize.current = windowResized.bind(this);
      window.addEventListener('resize', onResize.current);
    }
    return _ => {
      window.removeEventListener('resize', onResize.current);
    };
  }, [collapsed, lastResized]);

  function inputClose() {
    onChange('');
    if (collapsible) {
      setCollapsed(true);
    }
  }

  function inputKeyPress(keyCode) {
    if (keyCode === 27) {
      inputClose();
    }
  }

  return (
    <InputGroup className={classes.join(' ')} {...divProps}>
      { collapsed ?
        <Button onClick={() => collapsible && setCollapsed(!collapsed)}>
          <Icon src={I.search} />
        </Button>
        :
        <InputGroup.Text>
          <Icon src={I.search} />
        </InputGroup.Text>
      }
      <FormControl
        id={uid} ref={inputRef}
        value={value} placeholder={placeholder}
        onChange={e => onChange(e.target.value)}
        onKeyUp={e => inputKeyPress(e.keyCode)}
        aria-label={label || placeholder}
        aria-describedby={uid}
      />
      { value ? (
        <Button onClick={inputClose} variant="secondary">
          <Icon src={I.times} />
        </Button>
      ) : null }
    </InputGroup>
  );
}

export {
  SearchInput
};
