import { React, useCallback, useEffect, useRef } from 'react';
import PT from 'prop-types';
import { Alert, ListGroup } from 'react-bootstrap';

import S from '../core/strings';
import { CallState, NumID, SipClient } from '../voip/sip-client';
import { Call, CallDirection, Log } from '../voip/call-log';
import * as CI from './CallIcons';


/**
 * Call Controls.
 *
 * @param {object} props;
 * @param {SipClient} props.client;
 * @param {string} props.state;
 * @param {NumID} props.baseID;
 * @param {NumID} props.numID;
 * @param {string[]} props.numsOnHold;
 * @param {Log} props.callLog
 * @param {function} props.closeHook
 */
function CallControls(props) {
  const {
    client, state, baseID, numID, numsOnHold, callLog, closeHook,
  } = props;

  const stateRef = useRef(null);
  const callRef = useRef(null);

  // Methods
  // ========================================

  const inCall = useCallback(() => client.isInCall(), [client]);
  const hangup = () => client.hangup();
  const accept = () => client.accept();
  const reject = () => client.reject();
  const closeControls = useCallback(() => {
    // if no other call is in process or pending, release the CallControls to
    // be closed
    if (!inCall()) {
      closeHook();
    }
  }, [inCall, closeHook]);

  /**
   * Save current call log.
   */
  const logLastCall = useCallback(async () => {
    /** @type {Call} */
    const call = callRef.current;
    if (!call) {
      closeControls();
      return;
    }
    callRef.current = null;
    call.timeTerminated = Date.now();
    await callLog.addCall(call);
    await callLog.save();
    closeControls();
  }, [callLog, closeControls]);


  const handleNewCall = useCallback(async (direction) => {
    // first process current call if any
    await logLastCall();
    const incoming = direction === CallDirection.IN;
    const srcID = incoming ? numID : baseID;
    const dstID = incoming ? baseID : numID;
    callRef.current = Call.fromIDs(srcID, dstID, direction);
  }, [logLastCall, numID, baseID]);


  // Log handling
  // ========================================

  // capture state changes
  useEffect(() => {
    // do nothing if state unchanged
    if (state === stateRef.current) {
      return;
    }
    stateRef.current = state;

    // incoming/outgoing
    if (~[CallState.INCOMING, CallState.OUTGOING].indexOf(state)) {
      const direction = state === CallState.INCOMING ?
            CallDirection.IN : CallDirection.OUT;
      handleNewCall(direction);
    }

    // call answered
    else if (state === CallState.CALL) {
      /** @type {Call} */
      const call = callRef.current;
      if (call) {
        call.timeAccepted = Date.now();
      }
    }

    // in all other scenarios consider the current call as terminated
    else {
      logLastCall();
    }

    // terminating existing (last) call
  }, [state, handleNewCall, logLastCall]);

  // Rendering
  // ========================================

  let controls = '';

  // OUTGOING
  if (state === CallState.OUTGOING) {
    controls = (
      <>
        <div className="CallControls__state">{S.sipDialing}</div>
        <div className="CallControls__name">{numID.name}</div>
        <div className="CallControls__callActions">
          <CI.HangUp size={150} onClick={hangup} title={S.sipHangup} />
        </div>
      </>
    );
  }

  // INCOMING
  if (state === CallState.INCOMING) {
    controls = (
      <>
        <div className="CallControls__state">{S.sipIncoming}</div>
        <div className="CallControls__name">{numID.name}</div>
        <div className="CallControls__callActions">
          <CI.Dial size={150} onClick={accept} title={S.sipAccept} />
          <CI.HangUp size={150} onClick={reject} titls={S.sipReject} />
        </div>
      </>
    );
  }

  // CALL
  if (state === CallState.CALL) {
    controls = (
      <>
        <div className="CallControls__state">{S.sipCall}</div>
        <div className="CallControls__name">{numID.name}</div>
        <div className="CallControls__callActions">
          <CI.HangUp size={150} onClick={hangup} title={S.sipHangup} />
        </div>
      </>
    );
  }

  // numbers on hold
  let onHold = '';
  if (numsOnHold.length) {
    onHold = (
      <Alert variant="warning" className="CallControls__numsOnHold">
        <Alert.Heading className="CallControls__numsOnHold__title">
          {S.sipNumbersOnHold}:
        </Alert.Heading>
        <ListGroup>
          {numsOnHold.map((nID, idx) =>
            <ListGroup.Item key={idx}>{nID.name}</ListGroup.Item>)}
        </ListGroup>
      </Alert>
    );
  }

  return (
    <div className="CallControls">
      <div className="CallControls__call">
        {controls}
      </div>
      {onHold}
    </div>
  );
}
CallControls.propTypes = {
  client: PT.instanceOf(SipClient).isRequired,
  state: PT.string.isRequired,
  baseID: PT.instanceOf(NumID).isRequired,
  numID: PT.instanceOf(NumID).isRequired,
  numsOnHold: PT.arrayOf(PT.instanceOf(NumID)).isRequired,
  callLog: PT.instanceOf(Log).isRequired,
};


export default CallControls;
