import React, { useContext, useEffect } from 'react';
import PT from 'prop-types';
import * as yup from 'yup';
import Login from '../components/Login';
import { AuthContext } from './context';


/**
 * Authentication wrapper
 *
 * Display children only if a user is logged in.
 */
function AuthWrapper(props) {
  const auth = useContext(AuthContext);
  const user = User.load();
  useEffect(() => {
    if (!auth.user && user) {
      auth.setUser(user);
    }
  });
  if (!auth.user) {
    return <Login />;
  }
  return (
    <div>{props.children}</div>
  );
}


/**
 * SIP user object.
 *
 * @class
 * @constructor
 */
class User {
  constructor(attrs) {
    attrs = attrs || {};
    /**
     * Username.
     * @type {string}
     */
    this.username = attrs.username || null;
    /**
     * Password.
     * @type {string}
     */
    this.password = attrs.password || null;;
    /**
     * TIS Login.
     * @type {boolean}
     */
    this.tisLogin = Boolean(attrs.tisLogin);
    /**
     * TIS Sip Accounts
     * @type {SipUser[]}
     */
    this.sipAccounts = (attrs.sipAccounts || []).map(
      sa => new SipUser(sa.user, sa.password, sa.is_default));
  }

  displayName() {
    return this.username;
  }

  /**
   * @return {User}
   */
  static load() {
    let user = null;
    try {
      user = authSchema.cast(localStorage.getItem('user'));
    } catch {
    }
    return user ? new User(user) : null;
  };
}


class SipUser {
  /**
   * @param {string} user
   * @param {string} password
   * @param {bool} is_default
   */
  constructor(user, password, is_default=false) {
    this.user = user;
    this.password = password;
    this.is_default = is_default;
  }

  static sort(u1, u2) {
    if ((u1.is_default && !u2.is_default) || u1.user < u2.user) {
      return  -1;
    }
    if ((!u1.is_default && u2.is_default) || u1.user > u2.user) {
      return 1;
    }
    return 0;
  }
}


/**
 * SIP User access hook.
 */
function useAuth() {
  const auth = useContext(AuthContext);
  const user = auth.user;
  return {auth, user};
}


// Utility functions
// ========================================


/**
 * @param {User} user
 */
function fetchAuthHeaders(user) {
  const { username, password } = user;
  return { Authorization: `Basic ${btoa(username + ":" + password)}` }
}


// Schemas and types
// ========================================

const sipAccountSchema = yup.object({
  name: yup.string().required(),
  display_name: yup.string(),
  password: yup.string().required(),
  default: yup.boolean().default(false),
});


const sipAccountsSchema = yup.object().shape({
  username: yup.string().required(),
  accounts: yup.array().of(sipAccountSchema).min(1),
});



const sipUserSchema = yup.object({
  user: yup.string().required(),
  password: yup.string().required(),
  is_default: yup.boolean().default(false),
});


const authSchema = yup.object({
  username: yup.string().required(),
  password: yup.string().nullable(),
  tisLogin: yup.bool().default(false),
  sipAccounts: yup.array().of(sipUserSchema).min(1),
});


const authPropType = PT.shape({
  username: PT.string.isRequired,
  password: PT.string,
  tisLogin: PT.bool,
});


const LoginType = {
  TIS: 'TIS',
  SIP: 'SIP',
};
Object.freeze(LoginType);


export {
  LoginType,
  AuthWrapper,
  User,
  authSchema,
  authPropType,
  useAuth,
  fetchAuthHeaders,
  SipUser,
  sipAccountsSchema,
};
