import React from 'react';
import './App.scss';
import { AppContextProvider } from './core/context';
import { AuthWrapper } from './core/auth';
import Phone from './components/Phone';


function App() {

  return (
    <div className="App">
      <AppContextProvider>
        <AuthWrapper>
          <Phone />
        </AuthWrapper>
      </AppContextProvider>
    </div>
  );
}

export default App;
