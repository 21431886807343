//fontawesome icons
import {
  faChevronLeft,
  faEllipsisH,
  faSearch,
  faSpinner,
  faTimes,
} from '@fortawesome/free-solid-svg-icons';

// import {
// } from '@fortawesome/free-regular-svg-icons';

const Icons = {
  collapseLeft: faChevronLeft,
  ellipsis: faEllipsisH,
  search: faSearch,
  spinner: faSpinner,
  times: faTimes,
};

export default Icons;
